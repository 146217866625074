var produceAnimation = { exists: false };

jQuery.fn.random = function() {
	var randomIndex = Math.floor(Math.random() * this.length);  
	return jQuery(this[randomIndex]);
};


/* INFRA START */

var winWidth = 1024;
var winHeight = 768;
var scrollTop = 0;
var isDebug = false;
var isRtl = true;

var jparalax = [];
var jtrigger = [];
var jfixHeight = [];
var jfixWidth = [];

var uploadStarted = false;
var triedToSubmit = false;
var uploadedFileUrl = '';

if (!Array.prototype.indexOf) {
	Array.prototype.indexOf = function(obj, start) {
		for (var i = (start || 0), j = this.length; i < j; i++) {
			if (this[i] === obj) { return i; }
		}
		return -1;
	}
}

var queryForm = function(settings){
  var reset = settings && settings.reset ? settings.reset : false;
  var self = window.location.toString();
  var querystring = self.split("?");
  if (querystring.length > 1) {
    var pairs = querystring[1].split("&");
    for (i in pairs) {
      var keyval = pairs[i].split("=");
      if (reset || sessionStorage.getItem(keyval[0]) === null) {
        sessionStorage.setItem(keyval[0], keyval[1]);
      }
    }
  }
  
  //add ref
  // var refURL = document.referrer;
    var refURL = window.location.href;
   if(refURL != "")
        sessionStorage.setItem('ref',refURL);
  
  var hiddenFields = document.querySelectorAll("input[type=hidden]");
  for (var i=0; i<hiddenFields.length; i++) {
    var param = sessionStorage.getItem(hiddenFields[i].name);
    if (param) document.getElementById(hiddenFields[i].name).value = param;
  }
}


function doParalax() {
	var paralaxConst = 2*(scrollTop+90) + (winHeight-90);
	if ( jparalax.length ) {
		jparalax.each(function() {
			var jthis = jQuery(this);
			var paralax = jthis.data('paralax');

			switch ( paralax.type ) {
				case 'bg':
					elementTop = jthis.offset().top;
					elementHeight = jthis.height();
					break;
				case 'top':
				case 'bottom':
				case 'marginTop':
				case 'marginBottom':
				case 'height':
					if ( jthis.css('position') == 'relative' ) {
						elementTop = jthis.offset().top;
						elementHeight = jthis.height();
					} else {
						elementTop = jthis.offsetParent().offset().top;
						elementHeight = jthis.offsetParent().height();
					}
					break;
				default:
					return true;
			}

			switch( paralax.mode ) {
				case 'rel':
					// minParalax = elementTop - winHeight;
					// maxParalax = elementTop + elementHeight;
					// center = elementTop + elementHeight/2 - winHeight/2;
					// range = elementHeight/2+winHeight/2;
					// factor = (scrollTop-center)/range
					factor = ( paralaxConst - 2*elementTop - elementHeight ) / ( elementHeight + (winHeight-90) );

					if ( paralax.factor !== undefined ) {
						// adjust factor value to start, middle and end cues
						if ( factor < paralax.factor.middle )
							factor = Math.abs(factor) / ( paralax.factor.start - paralax.factor.middle ) + paralax.factor.middle;
						else
							factor = ( paralax.factor.end == paralax.factor.middle ) ? paralax.factor.middle : ( Math.abs(factor) / ( paralax.factor.end - paralax.factor.middle ) + paralax.factor.middle );
					}
					break;
				case 'px':
					factorStart = paralax.factor.start;
					factorMiddle = isNaN( paralax.factor.middle ) ? elementHeight/2 : paralax.factor.middle;
					factorEnd = isNaN( paralax.factor.end ) ? elementHeight : paralax.factor.end;

					// minParalax = elementTop + factorStart - winHeight;
					// maxParalax = elementTop + factorEnd;
					// center = elementTop + factorMiddle - winHeight/2;
					// negRange = factorMiddle - factorStart + winHeight/2;
					// posRange = factorEnd - factorMiddle + winHeight/2;
					// factor = (scrollTop-center)/range
					if ( (scrollTop+90) < ( elementTop + factorMiddle - winHeight/2 ) )
						factor = ( (scrollTop+90) - elementTop - factorMiddle + (winHeight-90)/2 ) / ( factorMiddle - factorStart + (winHeight-90)/2 );
					else
						factor = ( (scrollTop+90) - elementTop - factorMiddle + (winHeight-90)/2 ) / ( factorEnd - factorMiddle + (winHeight-90)/2 );
					break;
				case 'header':
					// minParalax = 0;
					// maxParalax = elementTop + elementHeight;
					// factor = scrollTop / maxParalax;
					factor = ( scrollTop / ( elementTop + elementHeight ) );
					break;
				case 'copy':
					sourceParalax = jQuery(paralax.copy).data('paralax');
					if ( ( sourceParalax !== null ) && ( sourceParalax.finalFactor !== null ) )
						factor = sourceParalax.finalFactor;
					else
						factor = paralax.minimum;
					break;
				default:
					return true;
			}

			// limit factor range
			paralax.finalFactor = Math.min(Math.max(paralax.minimum,factor),paralax.maximum);
			jthis.data('paralax',paralax);

			// fix huge screens
			if ( paralax.hugeFix && ( winWidth > hugeWidth ) ) {
				// Relative Offset
				distance = ( ( elementHeight + 2*paralax.distance ) * ( winWidth / hugeWidth ) - elementHeight )/2;
				// Absolute Offset
				// bgOffset = ( ( elementHeight + 2*paralax.distance ) * ( winWidth / hugeWidth ) - elementHeight )/2 - paralax.distance;
			} else
				distance = paralax.distance;

			switch ( paralax.type ) {
				case 'bg':
					if ( paralax.mode == 'header' )
						newTop = distance*paralax.finalFactor;
					else
						newTop = distance*paralax.finalFactor-Math.abs(distance);
					jthis.css('background-position','center '+newTop+'px');
					break;
				case 'top':
					jthis.css('top',(distance*paralax.finalFactor)+'px');
					break;
				case 'bottom':
					jthis.css('bottom',(distance*finalFactor)+'px');
					break;
				case 'marginTop':
					jthis.css('marginTop',(distance*paralax.finalFactor)+'px');
					break;
				case 'marginBottom':
					jthis.css('marginBottom',(distance*finalFactor)+'px');
					break;
				case 'height':
					originalHeight = jthis.css('height','').innerHeight();
					jthis.css('height',(originalHeight-(distance*paralax.finalFactor))+'px');
					break;
			}
		});
	}
}

function doTrigger() {
	if ( jtrigger.length ) {
		jtrigger.each(function() {
			var jthis = jQuery(this);
			var trigger = jthis.data('trigger');

			if ( trigger.once && trigger.activated )
				return true;

			if ( !trigger.target )
				return true;

			var triggerActive = false;
			var offset = trigger.offset;
			if ( trigger.mode == 'rel' ) {
				var elementHeight = jthis.outerHeight();
				offset = elementHeight * offset;
			}

			switch ( trigger.cue ) {
				case 'top':
					var elementTop = jthis.offset().top;
					if ( jthis.css('position') == 'fixed' )
						elementTop -= scrollTop;

					triggerActive = ( scrollTop >= ( elementTop + offset ) );
					break;
				case 'center':
					var elementTop = jthis.offset().top;
					if ( jthis.css('position') == 'fixed' )
						elementTop -= scrollTop;

					if ( trigger.mode != 'rel' )
						var elementHeight = jthis.outerHeight();

					triggerActive = ( ( scrollTop + ( winHeight / 2 ) ) >= ( elementTop + ( elementHeight / 2 ) + offset ) );
					break;
				case 'bottom':
					triggerActive = ( ( scrollTop + winHeight ) >= ( jthis.offset().top + offset ) );
					break;
				default:
					return true;
			}

			switch( trigger.type ) {
				case 'js':
					if (typeof window[trigger.target] === "function") {
						window[trigger.target](triggerActive);
					}
					break;
				case 'class':
					if ( trigger.target == 'this' )
						jtarget = jthis;
					else
						jtarget = jQuery(trigger.target);

					if ( jtarget.length && trigger.toggleClass ) {
						if ( triggerActive )
							jtarget.addClass(trigger.toggleClass);
						else
							jtarget.removeClass(trigger.toggleClass);
					}
					break;
			}

			if ( triggerActive ) {
				trigger.activated = true;
				jthis.data('trigger',trigger);
			}
		});
	}
}

function doFixHeight( tagFilter ) {
	var fixed = [];
	if ( jfixHeight.length ) {
		jfixHeight.filter( ( typeof( tagFilter ) == 'undefined' ) ? '*' : '[data-fix-height="'+tagFilter+'"]' ).each(function() {
			tag = jQuery(this).attr('data-fix-height');
			mode = jQuery(this).attr('data-fix-mode'); // all (default) / row
			if ( fixed.indexOf(tag) == -1 ) {
				if ( mode == 'row' ) {
					row = 1;
					rowTop = undefined;
					rowHeight = 0;
					jtagItems = jfixHeight.filter('[data-fix-height="'+tag+'"]').css('height','').removeAttr('data-row');
					jtagItems.each(function() {
						jitem = jQuery(this);
						itemTop = jitem.offset().top;
						itemHeight = jitem.height();
						if ( rowTop === undefined )
							rowTop = itemTop;
						else if ( rowTop != itemTop ) {
							jtagItems.filter('[data-row="'+row+'"]').css('height',rowHeight+'px');
							row++;
							rowTop = itemTop;
							rowHeight = 0;
						}
						jitem.attr('data-row',row);
						if ( itemHeight > rowHeight )
							rowHeight = itemHeight;
					});
					jtagItems.filter('[data-row="'+row+'"]').css('height',rowHeight+'px');
				} else { // all
					maxHeight = 0;
					jfixHeight.filter('[data-fix-height="'+tag+'"]').css('height','').each(function() {
						thisHeight = jQuery(this).height();
						if ( thisHeight > maxHeight )
							maxHeight = thisHeight;
					}).css('height',maxHeight+'px');
					fixed.push(tag);
				}
			}
		});
	}
}

function doFixWidth( tagFilter ) {
	var fixed = [];
	if ( jfixWidth.length ) {
		jfixWidth.filter( ( typeof( tagFilter ) == 'undefined' ) ? '*' : '[data-fix-width="'+tagFilter+'"]' ).each(function() {
			tag = jQuery(this).attr('data-fix-width');
			mode = jQuery(this).attr('data-fix-mode'); // margin (default) / resize
			if ( fixed.indexOf(tag) == -1 ) {
				jtagItems = jfixWidth.filter('[data-fix-width="'+tag+'"]').css('marginLeft','10px').css('marginRight','10px');

				if ( jtagItems.length <= 1 )
					return true;

				if ( mode == 'resize' ) {
					jtagItems.css('width','');
					widthRatio = 1;
					while ( ( jtagItems.first().offset().top != jtagItems.last().offset().top ) && widthRatio > 0 ) {
						widthRatio -= 0.1;
						jtagItems.each(function() {
							jitem = jQuery(this);
							jitem.css('width','').css('width',(jitem.outerWidth()*widthRatio)+'px');
						});
					}
					if ( !widthRatio )
						jtagItems.css('width','');
				}
				jcontainer = jtagItems.parent();
				if ( ( jcontainer.css('white-space') != 'nowrap' ) && ( jcontainer.css('display') != 'none' ) && jcontainer.height() ) {
					marginOffset = 10;
					while ( jtagItems.first().offset().top == jtagItems.last().offset().top ) {
						marginOffset++;
						jtagItems.css('marginLeft',marginOffset+'px').css('marginRight',marginOffset+'px');
						jtagItems.first().css('marginRight','0');
						jtagItems.last().css('marginLeft','0');
					}
					if ( marginOffset ) {
						jtagItems.css('marginLeft',(marginOffset-1)+'px').css('marginRight',(marginOffset-1)+'px');
						jtagItems.first().css('marginLeft','0');
						jtagItems.last().css('marginRight','0');
					}
				}
				fixed.push(tag);
			}
		});
	}
}

function winScroll() {
	scrollTop = jQuery(window).scrollTop();
	doParalax();
	doTrigger();

	if ( typeof window['doWinScroll'] === "function" )
		window['doWinScroll']();
}
function winResize( forceResize ) {
	if ( typeof window['beforeWinResize'] === "function" )
		window['beforeWinResize']();

	var newWidth = jQuery(window).width();
	var newHeight = jQuery(window).height();
	if ( forceResize || ( winHeight != newHeight ) || ( winWidth != newWidth ) ) {
		winWidth = newWidth;
		winHeight = newHeight;

		doFixHeight();
		doFixWidth();
		fixLogoMargins();
		winScroll();
	}

	if ( typeof window['doWinResize'] === "function" )
		window['doWinResize']();
	
	if( jQuery('#content .contact .contact-title').length ) {
		jQuery('#content .contact .contact-title').css({'whiteSpace': 'nowrap'});
		var oneLineHeight = jQuery('#content .contact .contact-title').height();
		jQuery('#content .contact .contact-title').css({'whiteSpace': 'normal'});
		if( oneLineHeight == jQuery('#content .contact .contact-title').height() ) {
		} else {
			jQuery('#content .contact .contact-title').closest('.ptable').addClass('multi-lines');
		}
	}
	
	expThumbsMinHeight = jQuery('#content .our-expertese .items').height();
	jQuery('#content .our-expertese .items').addClass('open');
	expThumbsMaxHeight = jQuery('#content .our-expertese .items').height();
	jQuery('#content .our-expertese .items').removeClass('open');
	
	if( jQuery('#content.process .del-icons').length ) {
		jQuery('#content.process .del-icons').each(function(){
			var jheight = '-'+(jQuery(this).outerHeight()+5)+'px';
			jQuery(this).css({'bottom': jheight});
			jQuery('#content.process .del-icons').removeClass('pre-init');
		});
	}
}

winResize(true);

jQuery(document).ready(function() {
	isDebug = jQuery('body').hasClass('debug');
	isRtl = jQuery('body').hasClass('rtl');
	
	if ( typeof window['beforeDocReady'] === "function" )
		window['beforeDocReady']();

	jparalax = jQuery('[data-paralax]');
	if ( jparalax.length ) {
		jparalax.each(function() {
			var jthis = jQuery(this);

			var paralax = {};
			paralax.type = jthis.attr('data-paralax'); // bg / top / bottom / marginTop / marginBottom / height
			paralax.distance = parseFloat( jthis.attr('data-para-distance') ); // in pixels
			if ( isNaN( paralax.distance ) )
				paralax.distance = 100;
			paralax.minimum = parseFloat( jthis.attr('data-para-min') ); // relative
			if ( isNaN( paralax.minimum ) )
				paralax.minimum = -1;
			paralax.maximum = parseFloat( jthis.attr('data-para-max') ); // relative
			if ( isNaN( paralax.maximum ) )
				paralax.maximum = 1;

			paralax.mode = jthis.attr('data-para-mode'); // rel (default) / px / header / copy
			if ( !paralax.mode )
				paralax.mode = 'rel';
			paralax.factor = {};
			switch( paralax.mode ) {
				case 'rel':
				case 'px':
					paralax.factor.start = parseFloat( jthis.attr('data-para-start') ); // in pixels or relative
					paralax.factor.middle = parseFloat( jthis.attr('data-para-mid') ); // in pixels or relative
					paralax.factor.end = parseFloat( jthis.attr('data-para-end') ); // in pixels or relative
					if ( !isNaN( paralax.factor.start ) || !isNaN( paralax.factor.middle ) || !isNaN( paralax.factor.end ) ) {
						switch ( paralax.mode ) {
							case 'rel':
								// cues are relative to factor
								if ( isNaN( paralax.factor.start ) )
									paralax.factor.start = -1;
								if ( isNaN( paralax.factor.middle ) )
									paralax.factor.middle = 0;
								if ( isNaN( paralax.factor.end ) )
									paralax.factor.end = 1;
								break;
							case 'px':
								// cues are in pixels
								if ( isNaN( paralax.factor.start ) )
									paralax.factor.start = 0;
								break;
						}
					} else {
						delete paralax.factor;
					}
					break;
				case 'copy':
					paralax.copy = jthis.attr('data-para-source');
					break;
			}

			paralax.hugeFix = jthis.attr('data-para-huge') !== undefined; // exists or not

			jthis.data('paralax',paralax);
		});
		doParalax();
	}
	
	jtrigger = jQuery('[data-trigger]');
	if ( jtrigger.length ) {
		jtrigger.each(function() {
			var jthis = jQuery(this);

			var trigger = {};
			trigger.type = jthis.attr('data-trigger'); // class / js
			trigger.once = ( jthis.attr('data-trig-once') === undefined ) ? false : true; // false (default), true
			trigger.target = jthis.attr('data-trig-target'); // for class: this (default) / selector, for js: function
			if ( trigger.target === undefined )
				trigger.target = 'this';
			trigger.cue = jthis.attr('data-trig-cue'); // bottom (default) / center / top
			if ( trigger.cue === undefined )
				trigger.cue = 'bottom';
			trigger.offset = parseFloat( jthis.attr('data-trig-offset') ); // in pixels
			if ( isNaN( trigger.offset ) )
				trigger.offset = 0;
			trigger.mode = jthis.attr('data-trig-mode'); // px (default) / rel
			if ( !trigger.mode )
				trigger.mode = 'px';

			if ( trigger.type == 'class' )
				trigger.toggleClass = jthis.attr('data-trig-class');

			jthis.data('trigger',trigger);
		});
		doTrigger();
	}

	jfixHeight = jQuery('[data-fix-height]');
	if ( jfixHeight.length )
		doFixHeight();
	jfixWidth = jQuery('[data-fix-width]');
	if ( jfixWidth.length )
		doFixWidth();

	if ( typeof window['doDocReady'] === "function" )
		window['doDocReady']();

	jQuery(window).scroll(function() {
		winScroll();
	}).resize(function() {
		winResize();
	}).load(function() {
		if ( typeof window['doWinReady'] === 'function' )
			window['doWinReady']();
		winResize(true);
	});

	winResize(true);
});

function beforeDocReady() {
	//jQuery('#content .contact .contact-title').attr('data-paralax','top').attr('data-para-distance',-100);
	//jQuery('#content .contact .contact-button').attr('data-paralax','marginTop').attr('data-para-distance',-200);
}

/* INFRA END */

function google_adword_conversion( google_conversion_id, google_conversion_label ) {
	var image = new Image(1, 1);
	image.src = "//www.googleadservices.com/pagead/conversion/" + google_conversion_id + "/?label=" + google_conversion_label;
}

function doWinReady() {
	jQuery('#page-loader').fadeOut(function() {
		jQuery(this).remove();
	});
	
	fullProcessLayout();
	doFixHeight('our-process-block');
	
	if(jQuery('#content .join-us .items').length)
		jQuery('#content .join-us .items').masonry();
	if(jQuery('#content.process .del-icons').length)
		jQuery('#content.process .del-icons').show();

	jQuery('iframe[data-src]').each(function() {
		var jiframe = jQuery(this);
		jiframe.attr('src',jiframe.attr('data-src')).removeAttr('data-src');
	});
	if(jQuery('#content.single-job').length) {
		jQuery('#content.single-job').height(jQuery('#content.single-job').height() - 264);
	}

	// Jobs Slider
	if ( typeof document.body.style.transition != 'undefined' ) {
		jQuery('.new-jobs-slider .slide:first-child').removeClass('reset').addClass('show');
	} else {
		jQuery('.new-jobs-slider .slide:first-child').fadeIn();
	}
}

function beforeWinResize() {
	if ( produceAnimation.exists ) {
		produceAnimation.jel.removeClass('animate');
		produceAnimation.jdivs.css('bottom','').css('left','').css('right','').css('opacity','').each(function() {
			var jdiv = jQuery(this);
			jdiv.data('startBottom',parseInt(jdiv.css('bottom')));
			jdiv.data('startLeft',parseInt(jdiv.css('left')));
			jdiv.data('startRight',parseInt(jdiv.css('right')));
			jdiv.data('startOpacity',parseInt(jdiv.css('opacity')));
		});
		produceAnimation.jel.addClass('animate');
		produceAnimation.jdivs.each(function() {
			var jdiv = jQuery(this);
			jdiv.data('endBottom',parseInt(jdiv.css('bottom')));
			jdiv.data('endLeft',parseInt(jdiv.css('left')));
			jdiv.data('endRight',parseInt(jdiv.css('right')));
			jdiv.data('endOpacity',parseInt(jdiv.css('opacity')));
		});
		produceAnimation.jel.removeClass('animate');
	}
}

function doWinResize() {
	if( winWidth > 1024 ) {
		var sliderWidth = jQuery('.slider .controls').outerWidth();
		var marginWidth = ( winWidth - sliderWidth ) / 2;
		jQuery('.slider .controls .arrow.prev').css('left',( marginWidth + jQuery('.slider .controls .arrow.prev').width() ) * -0.5 );
		jQuery('.slider .controls .arrow.next').css('right',( marginWidth + jQuery('.slider .controls .arrow.prev').width() ) * -0.5 );
	}

	// Adjust instagram image count
	var imageCount = 8;
	if ( ( winWidth <= 1200 ) && ( winWidth > 950 ) )
		imageCount = 6;
	else if ( winWidth <= 375 )
		imageCount = 4;
	var jofficeImages = jQuery('#content .office-images-wrapper .office-image');
	var jhiddenImages = jofficeImages.filter('.hidden');
	var visibleCount = jofficeImages.length - jhiddenImages.length;
	if ( visibleCount > imageCount ) {
		// Hide some images
		var jvisibleImages = jofficeImages.filter(':not(.hidden)');
		while ( visibleCount > imageCount ) {
			jvisibleImages.not( jvisibleImages.random().addClass('hidden') );
			visibleCount--;
		}
	} else if ( visibleCount < imageCount ) {
		// Show some images
		while ( visibleCount < imageCount ) {
			jhiddenImages.not( jhiddenImages.random().removeClass('hidden') );
			visibleCount++;
		}
	}

	jhomeThumbnailItems = jQuery('#content.home .thumbnails .items');
	if ( jhomeThumbnailItems.hasClass('open') ) {
		jhomeThumbnailItems.css('height','');
		var thumbsHeight = jhomeThumbnailItems.height();
		jhomeThumbnailItems.css('height',( thumbsHeight - 8 ) + 'px');
	}
	if(jQuery('.brands-logos').length)
		compareLogosHeight();

	// Jobs slider
	var slidesWidth = jQuery('.new-jobs-slider .slides').width();
	jQuery('.new-jobs-slider .slide').each(function() {
		var jslide = jQuery(this);
		var jreason = jQuery(this).find('.reason');
		reasonWidth = jreason.find('.inner').outerWidth();
		jreason.css('width',reasonWidth).css('left',reasonWidth);
		jreason.siblings('.bar').css('left',reasonWidth);
		jreason.siblings('.next').css('left',reasonWidth);
		var leftOffset = (slidesWidth-reasonWidth-250) / 2;
		//if ( leftOffset < ( slidesWidth - winWidth ) / 2 )
		//	leftOffset = 0;
		jslide.css('left',leftOffset);
	});
}

var lastScrollTop;

/*
// Bezier Curve

coord = function (x,y) {
	if(!x) var x=0;
	if(!y) var y=0;
	return {x: x, y: y};
}

function B1(t) { return t*t*t }
function B2(t) { return 3*t*t*(1-t) }
function B3(t) { return 3*t*(1-t)*(1-t) }
function B4(t) { return (1-t)*(1-t)*(1-t) }

function getBezier(factor,C1,C2,C3,C4) {
	var pos = new coord();
	pos.x = C1.x*B1(factor) + C2.x*B2(factor) + C3.x*B3(factor) + C4.x*B4(factor);
	pos.y = C1.y*B1(factor) + C2.y*B2(factor) + C3.y*B3(factor) + C4.y*B4(factor);
	return pos;
}
*/

function doWinScroll() {

	if(jQuery('#image-header').height() < (scrollTop+jQuery('#header').height())) {
		jQuery('#header').addClass('hide');
	}
	if ( scrollTop > ( ( winWidth > 1000 ) ? 0 : jQuery('#header').outerHeight() ) ) {
		jQuery('#header').addClass('sticky');
	}
	else if ( scrollTop == 0 )
		jQuery('#header').removeClass('sticky');

	if ( ( typeof lastScrollTop != 'undefined' ) && ( lastScrollTop > scrollTop ) ) {
		jQuery('#header').removeClass('hide');
		jQuery('body').addClass('scrolling-up');
	} else {
		jQuery('body').removeClass('scrolling-up');
	}

	var jinner = jQuery('#content .emerging-platforms .inner');
	if ( jinner.length ) {
		var paralax = jinner.data('paralax');
		if ( paralax ) {
			if ( jinner.data('paralax').finalFactor > 0 )
				jinner.parent().addClass('scrolled-after');
			else
				jinner.parent().removeClass('scrolled-after');
		}
	}
	
	if( jQuery('body.page-template-page-works #header').length ) {
		jQuery('body.page-template-page-works #header').addClass('sticky');
	}

	var jenvision = jQuery('#content .envision');
	if ( jenvision.length ) {
		var jimageWrapper = jenvision.find('.image-wrapper');
		var stripTop = jimageWrapper.offset().top;
		var stripHeight = jimageWrapper.outerHeight();
		if ( ( scrollTop + winHeight ) > ( stripTop + stripHeight -30 ) )
			jenvision.addClass('animate');
		else if ( ( scrollTop + winHeight ) < stripTop )
			jenvision.removeClass('animate');
	}

	var jdesign = jQuery('#content.process .design');
	if ( jdesign.length ) {
		var jimageWrapper = jdesign.find('.image-wrapper');
		var stripTop = jimageWrapper.offset().top;
		var stripHeight = jimageWrapper.outerHeight();
		if ( ( scrollTop + winHeight ) > ( stripTop + stripHeight -30 ) )
			jdesign.addClass('animate');
		else if ( ( scrollTop + winHeight ) < stripTop )
			jdesign.removeClass('animate');
	}

	if ( produceAnimation.exists ) {
		var startCue = 0.66
		var endCue = 0.4;
		var stripTop = produceAnimation.jimageWrapper.offset().top;
		var stripHeight = produceAnimation.jimageWrapper.height();

		if ( ( scrollTop + winHeight ) < ( stripTop + startCue * stripHeight ) )
			var factor = 0;
		else if ( ( scrollTop + ( endCue * winHeight ) ) > ( stripTop + startCue * stripHeight ) )
			var factor = 1;
		else
			var factor = ( ( scrollTop + winHeight ) - ( stripTop + startCue * stripHeight ) ) / ( ( 1 - endCue ) * winHeight );

		//factor = getBezier(factor,C1,C2,C3,C4);

		produceAnimation.jdivs.each(function() {
			var jdiv = jQuery(this);
			if ( jdiv.data('startBottom') != jdiv.data('endBottom') )
				jdiv.css('bottom',jdiv.data('startBottom') + ( factor * ( jdiv.data('endBottom') - jdiv.data('startBottom') ) ) );
			if ( jdiv.data('startLeft') != jdiv.data('endLeft') )
				jdiv.css('left',jdiv.data('startLeft') + ( factor * ( jdiv.data('endLeft') - jdiv.data('startLeft') ) ) );
			if ( jdiv.data('startRight') != jdiv.data('endRight') )
				jdiv.css('right',jdiv.data('startRight') + ( factor * ( jdiv.data('endRight') - jdiv.data('startRight') ) ) );
			if ( jdiv.data('startOpacity') != jdiv.data('endOpacity') )
				jdiv.css('opacity',jdiv.data('startOpacity') + ( factor * ( jdiv.data('endOpacity') - jdiv.data('startOpacity') ) ) );
		});
	}

	lastScrollTop = scrollTop;
}

function doDocReady() {
	jQuery('video').each(function() {
		if ( this.readyState == 4 ) {
			jQuery(this).addClass('ready').get(0).play();
		} else {
			jQuery(this).bind('canplaythrough',function() {
				jvideo = jQuery(this);
				if ( ( playbackRate = parseFloat(jvideo.attr('playback-rate')) ) && ( !isNaN( playbackRate ) ) )
					jvideo.get(0).playbackRate = playbackRate;
				jvideo.addClass('ready').get(0).play();
			});
		}
	});
	
	jQuery('#image-header.home .main .header-arrow').click(function(){
		jtarget = jQuery(jQuery(this).attr('href'));
		var jtargetTop = jtarget.offset().top;
		if ( jtarget ) {
			var scrollTarget = (jtargetTop)-(jQuery('#header').outerHeight(true)-36);
			jQuery('html, body').animate({scrollTop: scrollTarget},500);
		}
		return false;
	});

	//
	fixLogoMargins();
	if(jQuery('#content .services-filter .select-wrapper ul').length)
		jQuery('#content .services-filter .select-wrapper ul').tinyscrollbar();
	

	//See us in action slider (homepage)
	var sliderOptions = {
		swipe: true,
		magneticSwipe: true,
		interval: 10000,
		pauseOnHover: true,
		neverEnding: true,
		transition: 'fade',
	}
	jQuery('.see-action .slider').simpleSlider(sliderOptions);
	
	var slider = jQuery('.see-action .slider').data('simpleslider');
	var jslides = jQuery('.see-action .slider').find('.slide');
	if ( jslides.length > 1 ) {
		jcontrols = jQuery('<div class="controls site-width">');
		jcontrols.append('<a href="javascript:void(0)" class="arrow prev">');
		jcontrols.append('<a href="javascript:void(0)" class="arrow next">');
		jQuery('.see-action .slider').prepend(jcontrols);
	}
	jQuery('.see-action .slider .arrow.prev').click(function(){
		slider.prevSlide();
	});
	jQuery('.see-action .slider .arrow.next').click(function(){
		slider.nextSlide();
	});
	
	//Testimonials slider (homepage)
	jQuery('#content .testimonials .slider .slides').simpleSlider(sliderOptions);
	
	var slider2 = jQuery('#content .testimonials .slider .slides').data('simpleslider');
	var mobTestiSlider = jQuery('#content .testimonials .slider');
	var jslides2 = jQuery('#content .testimonials .slider').find('.slide');
	if ( jslides2.length > 1 ) {
		var jcontrols = jQuery('<div class="controls site-width">');
		jcontrols.append('<a href="javascript:void(0)" class="arrow prev">');
		jcontrols.append('<a href="javascript:void(0)" class="arrow next">');
		jQuery('#content .testimonials .slider').append(jcontrols);
		
		jdots = jQuery('<div class="dots">');
		jslides2.each(function() {
			jdots.append('<div class="dot">');
		});
		jdots.children().first().addClass('active');
		jcontrols.append(jdots);
	}
	jQuery('#content .testimonials .slider .arrow.prev').click(function(){
		slider2.prevSlide();
	});
	jQuery('#content .testimonials .slider .arrow.next').click(function(){
		slider2.nextSlide();
	});
	mobTestiSlider.find('.dot').click(function() {
		var slideIndex = jQuery(this).index();
		jQuery(this).addClass('active').siblings('.active').removeClass('active');
		slider2.nextSlide(slideIndex);
//		showSlideMob( mobTestiSlider, slideIndex );
	});
	
	//Thumbnails
	jQuery('#content.home .thumbnails .pumi-button-inv.plus-btn').first().click(function(){
		var moreBtn = jQuery(this);
		var jitems = jQuery(this).parent().find('.items');
		var thumbsStartHeight = jitems.height();
		jitems.addClass('open');
		var thumbsHeight = jitems.height();
		jitems.css('height',thumbsStartHeight+'px');
		jitems.animate({'height': thumbsHeight-8}, 600, function(){
			moreBtn.fadeOut();
			moreBtn.next().fadeIn();
		});
	});

	jQuery('#content.works .thumbnails .pumi-button-inv').first().click(function() {
		var moreBtn = jQuery(this);

		moreBtn.fadeOut();
		moreBtn.closest( '.thumbnails' ).append( '<div id="more_thumbnails" style="display:none;"></div>' );
		var currentUrl = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname;
		jQuery( '#more_thumbnails').load( currentUrl + 'page/2' + ' .thumbnails', function() {
			jQuery(this).slideDown();
		});
		return false;
	});
	
	
	jQuery('#content .brands-logos-wrapper .pumi-button-inv').first().click(function(){
		var moreBtn = jQuery(this);
		var jitems = jQuery(this).parent().find('.brands-logos');
		var thumbsStartHeight = jitems.height();
		jitems.css('height','').addClass('open');
		var thumbsHeight = jitems.height();
		jitems.css('height',thumbsStartHeight+'px');
		jitems.animate({'height': thumbsHeight}, 600, function(){ 
			moreBtn.fadeOut(600, function(){
				moreBtn.next().fadeIn(); 
			}); 
		});
		
	});
	jQuery('#content .brands-logos-wrapper .pumi-button-inv:eq(1)').click(function(){
		var lessBtn = jQuery(this);
		var jitems = jQuery(this).parent().find('.brands-logos');
		jitems.animate({'height': 760}, 600, function(){
			lessBtn.fadeOut(function(){
				lessBtn.prev().fadeIn();
			});
		});
	});
	
	expThumbsMinHeight = jQuery('#content .our-expertese .items').height();
	jQuery('#content .our-expertese .items').addClass('open');
	expThumbsMaxHeight = jQuery('#content .our-expertese .items').height();
	jQuery('#content .our-expertese .items').removeClass('open');
	
	jQuery('#content .our-expertese .pumi-button-inv').first().click(function(){
		var moreBtn = jQuery(this);
		var jitems = jQuery(this).parent().find('.items');
		jitems.animate({'height': expThumbsMaxHeight}, 600, function(){ 
			moreBtn.fadeOut(); moreBtn.next().fadeIn(); 
			jitems.addClass('open');
		});
		
	});
	jQuery('#content .our-expertese .pumi-button-inv:eq(1)').click(function(){
		var lessBtn = jQuery(this);
		var jitems = jQuery(this).parent().find('.items');
		jitems.animate({'height': expThumbsMinHeight}, 600, function(){
			lessBtn.fadeOut();
			lessBtn.prev().fadeIn();
		});
	});
	
	//Textual services list (full services list page)
	jQuery('#content .textual-wrapper .pumi-button-inv').first().click(function(){
		var moreBtn = jQuery(this);
		var jitems = jQuery(this).parents('.textual-wrapper').find('.textual-services-list');
		jitems.addClass('open');
		var thumbsHeight = jitems.height();
		jitems.removeClass('open');
		jitems.animate({'height': thumbsHeight}, 600, function(){ 
			moreBtn.fadeOut(); moreBtn.next().fadeIn(); 
		});	
	});
	jQuery('#content .textual-wrapper .pumi-button-inv:eq(1)').click(function(){
		var lessBtn = jQuery(this);
		var jitems = jQuery(this).parents('.textual-wrapper').find('.textual-services-list');
		jitems.animate({'height': 350}, 600, function(){
			lessBtn.fadeOut();
			lessBtn.prev().fadeIn();
		});
	});
	
	
	var clntThumbsMinHeight = jQuery('#content .clients-list').height();
	//Clients - logos list
	jQuery('#content .clients-list-wrapper .pumi-button-inv').first().click(function(){
		doFixWidth();
		
		jQuery('#content .clients-list').addClass('open');
		var clntThumbsMaxHeight = jQuery('#content .clients-list').height();
		jQuery('#content .clients-list').removeClass('open');
		
		var moreBtn = jQuery(this);
		var jitems = jQuery(this).parents('.clients-list-wrapper').find('.clients-list');
		jitems.animate({'height': clntThumbsMaxHeight}, 600, function(){ 
			moreBtn.fadeOut(); moreBtn.next().fadeIn(); 
		});	
	});
	jQuery('#content .clients-list-wrapper .pumi-button-inv:eq(1)').click(function(){
		var lessBtn = jQuery(this);
		var jitems = jQuery(this).parents('.clients-list-wrapper').find('.clients-list');
		jitems.animate({'height': clntThumbsMinHeight}, 600, function(){
			lessBtn.fadeOut();
			lessBtn.prev().fadeIn();
		});
	});
	
	
	//Jobs Hover
	if(jQuery('#content .join-us .items').length && jQuery(window).width() > 600)
		jQuery('#content .join-us .items').masonry();
	
	jQuery('#content.jobs .items .item .more-button').mouseenter(function(){
		jQuery(this).css({'backgroundColor': jQuery(this).css('borderColor')}); 
	}).mouseleave(function(){
		if(jQuery(this).parents('.item').hasClass('general')) {
			jQuery(this).css({'backgroundColor': 'transparent'});
			return false;
		}
		jQuery(this).css({'backgroundColor': '#fff'});
	});
	
	new Clipboard('#d-clip-button');
	
	
	//Contact form
	jQuery('#content .contact .contact-button').click(function(){
		jQuery(this).slideUp(500);
		jQuery(this).siblings('.contact-title').animate({'top': '-=60', 'opacity': '0', 'height': '0'}, 500, function(){
			//jQuery(this).animate({});
			jQuery(this).css({'visibility': 'hidden'});
		});
		jQuery(this).siblings('.form-wrapper').addClass('open');
		//jQuery(this).siblings('.location').slideDown(800);
		jQuery('html, body').animate({scrollTop: jQuery('#contact-strip').offset().top});
	});

	jQuery('#content .contact .form-wrapper .inputs-wrapper.exp input').focus(function(){
		jQuery(this).parents('.about-you .inputs-row').find('.inputs-wrapper.exp input.hidden').each(function(){
			jQuery(this).slideDown().parents('.inputs-wrapper').addClass('open');
		});
	});
	
	if( jQuery('#content.single-job .contact').length ) {
		var singlejContactHeight = jQuery('#content.single-job .contact').outerHeight();
	}
	
	//Contact Form Validation
	jQuery('#content .form-wrapper .pumika-contact-form').submit(function() {
		if (uploadStarted) {
			triedToSubmit = true;
			return false;
		}

		var jform = jQuery(this);
		jform.find('input').removeClass('error');
		var errorMsg = 'Please enter the missing field';
		
		var jfullname = jform.find('input[name="fullname"]');
		var fullname = jfullname.val();
		var jcompany = jform.find('input[name="company"]');
		var company = jcompany.val();
		var jtitle = jform.find('input[name="title"]');
		var title = jtitle.val();
		var jemail = jform.find('input[name="email"]');
		var email = jemail.val();
		var jphone = jform.find('input[name="phone"]');
		var phone = jphone.val();
		
		if ( fullname && email ) {
			re = /\S+@\S+\.\S+/;
			if ( re.test(email) ) {
				if( jQuery(this).parents('#content').hasClass('contact'))
						jform.parents('.site-width').css({'height': jform.parents('.site-width').height()});
				jform.parents('.form-wrapper').fadeOut();
				jQuery(this).parent().siblings('.join-newsletter').find('input[name="email"]').val(email);
				jQuery(this).parent().siblings('.join-newsletter').show().find('p, .newsletter-form').show();
				var formTargetHeight = jQuery(this).parent().siblings('.join-newsletter').height();
				

				jQuery(this).parent().siblings('.join-newsletter').find('p, .newsletter-form').hide();
//				if( !jform.parents('.contact-wrapper').length )
//					jQuery('html, body').animate({scrollTop: jQuery('#image-header').outerHeight(true)});
//				else
					jQuery('html, body').animate({scrollTop: jform.offset().top-50});
				if( jQuery(this).parents('#content').hasClass('contact')) {
					jform.parents('.site-width').animate({'height': formTargetHeight}, function(){
						jform.parent().siblings('.join-newsletter').fadeIn();
						jform.parent().siblings('.join-newsletter').find('p:eq(0)').fadeIn(1000, function(){
							jQuery(this).delay(1000).siblings('*').fadeIn();
						});
					});
				} else {
					jform.parent().siblings('.join-newsletter').fadeIn();
					jform.parent().siblings('.join-newsletter').find('p:eq(0)').fadeIn(1000, function(){
						jQuery(this).delay(1000).siblings('*').fadeIn();
					});
				}
			} else {
				jemail.addClass('error');
				jform.find('.error-response').html(errorMsg).show();
				return false;
			}
		} else {
			if ( !fullname ) {
				jfullname.addClass('error');
				jform.find('.error-response').html(errorMsg).show();
			} else if ( !email ) {
				jemail.addClass('error');
				jform.find('.error-response').html(errorMsg).show();
			}
			return false;
		}
		
		
		jform.find('.ajax').addClass('show');
		var data = jform.serializeArray();
		data.push({name: 'action', value: 'pumika_contact'});
		if (uploadedFileUrl)
			data.push({name: 'file', value: uploadedFileUrl});
		jQuery.post(
			pumika.ajaxurl,
			data,
			function( response ) {
				jform.find('.ajax').removeClass('show');
				if ( response.success && response.script ) {
					eval( response.script );
				}
			}
		);

		return false;
	}).find('input, textarea').focusin(function() {
		jQuery(this).removeClass('error');
	});

	jQuery('#footer .newsletter-form').submit(function() {
		var jform = jQuery(this);
		jform.find('input').removeClass('error');
		
		var jemail = jform.find('input[name="email"]');
		var email = jemail.val();
		
		re = /\S+@\S+\.\S+/;
		if ( email && re.test(email) ) {
			var data = jform.serializeArray();
			jform.find('.ajax').addClass('show');
			data.push({name: 'action', value: 'pumika_newsletter'});
			jQuery.post(
				pumika.ajaxurl,
				data,
				function( response ) {
					jform.find('.ajax').removeClass('show');
					if ( response.success && response.script ) {
						eval( response.script );
					}
				}
			);

			jQuery(this).addClass('sent');
			jform.find('input[type="text"]').prop('readonly', true); 
			jform.find('input[type="submit"]').val('submitted');
			jQuery(this).siblings('.thanks').text("Thank you! We'll be in touch").fadeIn();		
		} else {
			jemail.addClass('error');
		}	
		return false;
	}).find('input').focusin(function() {
		jQuery(this).removeClass('error');
	});
	
	jQuery('#content .newsletter .newsletter-form').submit(function() {
		var jform = jQuery(this);
		jform.find('input').removeClass('error');
		
		var jemail = jform.find('input[name="email"]');
		var email = jemail.val();
		re = /\S+@\S+\.\S+/;
		if ( email && re.test(email) ) {
			var data = jform.serializeArray();
			jform.find('.ajax').addClass('show');
			data.push({name: 'action', value: 'pumika_newsletter'});
			jQuery.post(
				pumika.ajaxurl,
				data,
				function( response ) {
					jform.find('.ajax').removeClass('show');
					if ( response.success && response.script ) {
						eval( response.script );
					}
				}
			);

			//jQuery(this).addClass('sent');
			jform.find('input[type="text"]').prop('readonly', true); 
			jform.find('input[type="submit"]').val('submitted');
			jQuery(this).fadeOut(600, function(){
				jQuery(this).siblings('.thanks').text("You are IN :)").fadeIn();
			});		
		} else {
			jemail.addClass('error');
		}	
		return false;
	}).find('input').focusin(function() {
		jQuery(this).removeClass('error');
	});
	
	if( jQuery('.venobox').length ) {
		jQuery('.venobox').venobox();
	}
	
	jQuery('#content .join-newsletter .newsletter-form').submit(function() {
		var jform = jQuery(this);
		jform.find('input').removeClass('error');

		var jemail = jform.find('input[name="email"]');
		var email = jemail.val();
		re = /\S+@\S+\.\S+/;
		if ( email && re.test(email) ) {
			var data = jform.serializeArray();
			jform.find('.ajax').addClass('show');
			data.push({name: 'action', value: 'pumika_newsletter'});
			jQuery.post(
				pumika.ajaxurl,
				data,
				function( response ) {
					jform.find('.ajax').removeClass('show');
					if ( response.success && response.script ) {
						eval( response.script );
					}
				}
			);

			//jQuery(this).addClass('sent');
			jform.find('input[type="text"]').prop('readonly', true);
			jform.find('input[type="submit"]').val('submitted');
			jQuery(this).fadeOut(600, function(){
				jQuery(this).parents('.join-newsletter').fadeOut().siblings('.thanks').fadeIn();
			});
		} else {
			jemail.addClass('error');
		}
		return false;
	}).find('input').focusin(function() {
		jQuery(this).removeClass('error');
	});

	if( jQuery('.venobox').length ) {
		jQuery('.venobox').venobox();
	}

	jQuery('#content .contact .form-wrapper .interested-in .sec-title').click(function(){
		jQuery(this).parent().addClass('open');
		jQuery(this).siblings('.opened').slideDown(800);
		jQuery('#content .contact .form-wrapper').siblings('.location').fadeOut();
		return false;
	});
	jQuery('#content .contact .form-wrapper .interested-in .sec-title').focus(function(){
		jQuery(this).keydown(function(e) {
			if (e.keyCode == 13) { // enter
				
			}
		});
	});
	
	jQuery('#content .contact .form-wrapper .interested-in .wrapper .tabs-content-wrapper > div').first().addClass('active');
	jQuery('#content .contact .form-wrapper .interested-in .wrapper .tabs-menu li').first().addClass('active');
	jQuery('#content .contact .form-wrapper .interested-in .wrapper .tabs-menu li').click(function(){
		var tabIndex = jQuery(this).index();
		var tmpOriginHeight = jQuery('#content .contact .form-wrapper .interested-in .opened').outerHeight();
		jQuery(this).addClass('active').siblings('li').removeClass('active');
		jQuery(this).parents('.tabs-menu').siblings('.tabs-content-wrapper').children('.active').removeClass('active');
		jQuery(this).parents('.tabs-menu').siblings('.tabs-content-wrapper').children('div:eq('+tabIndex+')').addClass('active');
		var tmpOpenedHeight = jQuery('#content .contact .form-wrapper .interested-in .opened').outerHeight();
		jQuery('#content .contact .form-wrapper .interested-in .opened').css({'height': tmpOriginHeight});
		jQuery('#content .contact .form-wrapper .interested-in .opened').animate({'height': tmpOpenedHeight}, function() {
			jQuery(this).css('height', '');
		});
		var jSelected = jQuery('.pumika-contact-form .interested-in input[name="selected-tab"]');
		switch (tabIndex) {
			case 0:
				jSelected.val('New Business');
				break;
			case 1:
				jSelected.val('Partnership');
				break;
			case 2:
				jSelected.val('Media Inquiries');
				break;
			case 3:
				jSelected.val('Apply For A Job');
				break;
			case 4:
				jSelected.val('Say Hello');
				break;
		}
		return false;
	});


    jQuery('.menu-contact-activation').click(function(){
        if(jQuery(this).hasClass('jobtab')){
            jQuery('#content .contact .form-wrapper .interested-in .wrapper .tabs-menu li').removeClass('active');
            jQuery('#content .contact .form-wrapper .interested-in .wrapper .tabs-menu li#jobtab').addClass('active');
            jQuery( '#contact-strip .form-wrapper .interested-in .sec-title' ).click();
        }
        if(jQuery(this).hasClass('partnertab')){
            jQuery('#content .contact .form-wrapper .interested-in .wrapper .tabs-menu li').removeClass('active');
            jQuery('#content .contact .form-wrapper .interested-in .wrapper .tabs-menu li#partnertab').addClass('active');
            jQuery( '#contact-strip .form-wrapper .interested-in .sec-title' ).click();
        }
        if(jQuery(this).hasClass('contact')){
            jQuery('#content .contact .form-wrapper .interested-in .wrapper .tabs-menu li').removeClass('active');
            jQuery('#content .contact .form-wrapper .interested-in .wrapper .tabs-menu li#newbiztab').addClass('active');
            jQuery( '#contact-strip .form-wrapper .interested-in .sec-title' ).click();
		}
        jQuery('#content .contact .contact-button').click();
    });
	
	/*
	jQuery('#content .contact .form-wrapper .message-wrapper input[type="file"]').change(function(){
		jQuery(this).siblings('input[type="text"]').val(jQuery(this).val()).parent().addClass('show');
		jQuery(this).siblings('.pfile').hide();
		jQuery(this).siblings('.remove').show();
	});
	*/
	
	// Pseudo checkbox
	var checkDiv = '<span class="pcheck">';
	jQuery('#content .contact .pcheck').each( function() {
		jcheck = jQuery(this);
		jcheck.parent().prepend(checkDiv);
		jcheck.attr('checked',false);
		jcheck.hide();
	}).change(function() {
		jcheck = jQuery(this);
		if( jcheck.is(':checked') ) {
			jcheck.siblings('.pcheck').addClass('checked');
			jcompareForm = jcheck.parents('.compare-form').first();
			if ( jcompareForm.length ) {
				if ( jcompareForm.find('input[type="checkbox"]:checked').length > 5 ) {
					jcheck.attr('checked',false).siblings('.pcheck').removeClass('checked');
					jQuery('#content #companies-filter .companies-table .compare .instruction').addClass('error');
				}
			}
		} else {
			jcheck.siblings('.pcheck').removeClass('checked');
			jQuery('#content #companies-filter .companies-table .compare .instruction').removeClass('error');
		}
	});
	
	//Partners combo
	var tmpSelect = '<div class="select-wrapper"><select class="pselect" placeholder="place">';
	var typesLength = jQuery('#content .the-reasons-wrapper .reasons-section-title').length;
	jQuery('#content .the-reasons-wrapper .reasons-title .ul-wrapper').prepend(tmpSelect);
	for( i=1; i<=typesLength; i++) {
		jQuery('#content .the-reasons-wrapper .reasons-title select').append('<option>')
			.find('option:eq('+(i-1)+')').val(jQuery('#content .the-reasons-wrapper .reasons-section-title:eq('+(i-1)+')').text())
			.text(jQuery('#content .the-reasons-wrapper .reasons-section-title:eq('+(i-1)+')').text());
		if(i==1){
			jQuery('#content .the-reasons-wrapper .reasons-title span').text(jQuery('#content .the-reasons-wrapper .reasons-section-title:eq(0)').text());
			jQuery('#content .the-reasons-wrapper .reasons-title select').attr('placeholder', jQuery('#content .the-reasons-wrapper .reasons-section-title:eq(0)').text());
		}
	}
	//
	jQuery('#content .the-reasons-wrapper .reasons-title .select-wrapper').width(jQuery('#content .the-reasons-wrapper .reasons-title span').width()+38);
	jQuery('#content .the-reasons-wrapper .reasons-title span').remove();
	
	
	
	//Pseudo Select
	var pselectInput = '<input type="text" class="pselect" readonly>';
	var hiddenInput = '<input type="hidden">';
	var numOfSelects = jQuery('.pselect').length;
	

	for(i=0; i < numOfSelects; i++){
		var numOfOptions = jQuery('select:eq('+i+') option').length;
		jQuery('.select-wrapper:eq('+i+')').append('<span>');
		jQuery('.select-wrapper > span:eq('+i+')').append(pselectInput);
		if(typesLength > 0){
			jQuery('.select-wrapper > span:eq('+i+') input.pselect').width(jQuery('#content .the-reasons-wrapper .reasons-title .select-wrapper').width()).val(jQuery('#content .the-reasons-wrapper .reasons-section-title:eq(0)').text());
		}
		jQuery('.select-wrapper > span:eq('+i+')').append(hiddenInput);
		jQuery('.select-wrapper > span:eq('+i+')').append('<ul>').find('input[type="text"]').attr('data-max-drop', jQuery('.select-wrapper select:eq('+i+')').attr('data-max-drop'));
		for(k=1; k<=numOfOptions; k++){
			jQuery('.select-wrapper:eq('+i+') ul').append('<li>');
			jQuery('.select-wrapper:eq('+i+') ul li').last().attr('datavalue', jQuery('.select-wrapper:eq('+i+') option:eq('+(parseInt(k)-1)+')').val()).text(jQuery('.select-wrapper:eq('+i+') option:eq('+(parseInt(k)-1)+')').text());
			
			if(jQuery('.select-wrapper:eq('+i+')').parent().hasClass('filter-select')) {
				jQuery('.select-wrapper:eq('+i+') ul li').last().prepend('<span>').find('span').attr('style', jQuery('.select-wrapper:eq('+i+') option:eq('+(parseInt(k)-1)+')').attr('style'));
			}
			
			if(jQuery('.select-wrapper:eq('+i+') option:eq('+(parseInt(k)-1)+')').is('[selected]')) {
				jQuery('.select-wrapper:eq('+i+')').find('input[type="hidden"]').val(jQuery('.select-wrapper:eq('+i+') option:eq('+(parseInt(k)-1)+')').val());
				jQuery('.select-wrapper:eq('+i+')').find('input.pselect').val(jQuery('.select-wrapper:eq('+i+') option:eq('+(parseInt(k)-1)+')').text());
			}
		}
	}
	var j = 0;
	for(i=0; i < numOfSelects; i++){
		jQuery('.select-wrapper:eq('+i+') input.pselect').each(function(){
			jQuery(this).attr('placeholder', jQuery('select.pselect:eq('+j+')').attr('placeholder'));
			//console.log(jQuery('select.pselect:eq('+j+')').attr('placeholder'));
			jQuery(this).attr('name', jQuery('select.pselect:eq('+j+')').attr('id'));
			jQuery('.select-wrapper span input[type="hidden"]:eq('+j+')').attr('name', jQuery('select:eq('+j+')').attr('name'));
			j++;
		});
	}
	jQuery('.select-wrapper select.pselect').remove();
	
	if ( jQuery().tinyscrollbar !== undefined ) {
		for(i=0; i < jQuery('.pselect').length; i++) {
			var dataMax = jQuery('.pselect:eq('+i+')').attr('data-max-drop');
			var numOfOptions = jQuery('.select-wrapper:eq('+i+') span ul li').length;
			if((dataMax < numOfOptions) && (dataMax > 0) ) {
				var dataHeight = 0;
				for(k=0; k<dataMax; k++){
					jQuery('.select-wrapper:eq('+i+') span ul').show();
					dataHeight += jQuery('.select-wrapper:eq('+i+') span ul li:eq('+k+')').outerHeight(true);
					jQuery('.select-wrapper:eq('+i+') span ul').hide();
				}
				
				jscrollables = jQuery('.select-wrapper:eq('+i+') span ul');
				jscrollables.wrapInner('<div class="viewport"><div class="overview"></div></div>')
					.prepend('<div class="scrollbar"><div class="track"><div class="thumb"><div class="end"></div></div></div></div>')
					.tinyscrollbar();
				jQuery('.select-wrapper:eq('+i+') .viewport').css('height',dataHeight+'px');
			}
		}
	}
	
	jQuery('.select-wrapper span input[type="text"]').click(function(){
		jQuery(this).siblings('ul').slideToggle();
		for(i=0; i < jQuery('.pselect').length; i++) {
			if(jQuery(this).siblings('ul').find('.viewport').length > 0) {
				var box = jQuery(this).siblings('ul').data("plugin_tinyscrollbar");
				box.update();
			}
		}
	});
	jQuery('.select-wrapper span input[type="text"]').focus(function(){
		jQuery(this).keydown(function(e) {
			if (e.keyCode == 40) { // down
				var selected = jQuery(this).siblings('ul').find('.selected').next();
				if(selected.length == 0)
					selected = jQuery(this).siblings('ul').find('li').first();
				jQuery(this).siblings('ul').find('li').removeClass("selected");
				selected.addClass("selected");
				return false;
			}
			if (e.keyCode == 38) { // up
				var selected = jQuery(this).siblings('ul').find('.selected').prev();
				if(jQuery(this).siblings('ul').find('.selected').prev().index() < 0)
					selected = jQuery(this).siblings('ul').find('li').last();
				jQuery(this).siblings('ul').find('li').removeClass("selected");
				selected.addClass("selected");
				return false;
			}
			if (e.keyCode == 13) { // enter
				jQuery(this).parents('.select-wrapper').find('span input[type="hidden"]').val(jQuery(this).siblings('ul').find('.selected').attr('datavalue')).change();
				jQuery(this).parents('.select-wrapper').find('span .pselect').val(jQuery(this).siblings('ul').find('.selected').text());
				if(jQuery(this).parents('.filter-select').length) {
					jQuery(this).parents('.select-wrapper').find('.pselect').css({'backgroundImage': jQuery(this).siblings('ul').find('.selected').find('span').css('backgroundImage')});
				}
				if(jQuery(this).siblings('ul').is(':visible')) {
					console.log(jQuery(this));
					jQuery(this).siblings('ul').slideUp();
				} else {
					jQuery(this).siblings('ul').slideDown();
				}
			}
		});
	});
	jQuery("#content .services-filter .filter-select .pselect").click(function(e) {
		e.stopPropagation();
	});
	jQuery(document).click(function(){
		jQuery("#content .services-filter .filter-select ul:visible").slideUp();
	});
	
	
	jQuery('.select-wrapper ul li').click(function(){
		jQuery(this).parents('.select-wrapper').find('span input[type="hidden"]').val(jQuery(this).attr('datavalue')).change();
		jQuery(this).parents('.select-wrapper').find('span .pselect').val(jQuery(this).text());
		if(jQuery(this).parents('.filter-select').length) {
			jQuery(this).parents('.select-wrapper').find('.pselect').css({'backgroundImage': jQuery(this).find('span').css('backgroundImage')});
		}
		jQuery(this).parents('ul').slideToggle();
	});
	
	jQuery('#content .the-reasons-wrapper .reasons-title ul li').click(function(){
		jQuery('#content .the-reasons-wrapper').removeClass('development funds marketing advertising other motion').addClass(jQuery('#content .the-reasons-wrapper .section-reasons:eq('+jQuery(this).index()+')').attr('par-type'));
		jQuery('#content .the-reasons-wrapper .section-reasons').hide();
		jQuery('#content .the-reasons-wrapper .section-reasons:eq('+jQuery(this).index()+')').fadeIn(800);
		jQuery('#content .the-reasons-wrapper .reasons-title').prepend('<span class="tmpTitle">'+jQuery('#content .the-reasons-wrapper .reasons-section-title:eq('+jQuery(this).index()+')').text()+'</span>');
		jQuery('#content .the-reasons-wrapper .select-wrapper input.pselect').width(jQuery('#content .the-reasons-wrapper .reasons-title .tmpTitle').width()+24);
		jQuery('#content .the-reasons-wrapper .reasons-title .select-wrapper').width(jQuery('#content .the-reasons-wrapper .reasons-title .tmpTitle').width()+24);
		
		var contWidth = jQuery('#content .the-reasons-wrapper .reasons-title .tmpTitle').width()+24;
		jQuery('#content .the-reasons-wrapper .reasons-title .select-wrapper ul').show();
		var ulWidth = jQuery('#content .the-reasons-wrapper .reasons-title .select-wrapper ul').width();
		jQuery('#content .the-reasons-wrapper .reasons-title .select-wrapper ul').hide();
		jQuery('#content .the-reasons-wrapper .reasons-title .select-wrapper ul').css({'left':-((ulWidth-contWidth)/2)});
		
		var fixTag = 'reason-'+jQuery('#content .the-reasons-wrapper .section-reasons:eq('+jQuery(this).index()+')').attr('par-type');
		doFixHeight(fixTag);
		jQuery('#content .the-reasons-wrapper .reasons-title .tmpTitle').remove();
	});
		
	
	jQuery('#content .partnership-areas .items .item').click(function(){
		jtarget = jQuery('#reasons-list');
		var jtargetTop = jtarget.offset().top;
		if ( jtarget ) {
			var scrollTarget = (jtargetTop)-(jQuery('#header').outerHeight(true))-40;
			jQuery('html, body').animate({scrollTop: scrollTarget},500);
			displayReasons(jQuery(this));
		}
		return false;
	});
	
	jQuery('#content .process-hl-wrapper a.choose-proj-btn').click(function(){
		jtarget = jQuery('#project-process');
		var jtargetTop = jtarget.offset().top;
		if ( jtarget ) {
			var scrollTarget = (jtargetTop)-(jQuery('#header').outerHeight(true));
			jQuery('html, body').animate({scrollTop: scrollTarget},500);
		}
		return false;
	});

	// Jobs Page - positions menu
	jQuery('#image-header.jobs .main .looking .looking-link').click(function(){
		jtarget = jQuery('#content .join-us');
		var jtargetTop = jtarget.offset().top;
		if ( jtarget ) {
			var scrollTarget = (jtargetTop)-(jQuery('#header').outerHeight(true)+50);
			jQuery('html, body').animate({scrollTop: scrollTarget},500);
		}

		return false;
	});

	jQuery( '.apply-job-wrapper .apply-button' ).click(function() {
		jtarget = jQuery('.job-contact-title');
		var jtargetTop = jtarget.offset().top;
		if ( jtarget ) {
			var scrollTarget = (jtargetTop)-(jQuery('#header').outerHeight(true));
			jQuery('html, body').animate({scrollTop: scrollTarget},500);
		}
		return false;
	});
	
	
	jQuery('#contact-strip .partners-jobs-wrapper .partners a.learn-button').click(function(){
		jtarget = jQuery('#contact-strip');
		var jtargetTop = jtarget.offset().top;
		if ( jtarget ) {
			var scrollTarget = (jtargetTop)-(jQuery('#header').outerHeight(true));
			jQuery('html, body').animate({scrollTop: scrollTarget},500);
		}

		jQuery( '#contact-strip .contact-wrapper a.contact-button' ).click();
		jQuery( '#contact-strip .form-wrapper .interested-in .sec-title' ).click();
		jQuery( '#contact-strip .form-wrapper .interested-in .tabs-menu li:eq(1) a' ).click();

		return false;
	});

	function displayReasons(el) {
		jQuery('#content .the-reasons-wrapper').removeClass('development funds marketing advertising other motion').addClass(jQuery('#content .the-reasons-wrapper .section-reasons:eq('+el.index()+')').attr('par-type'));
		jQuery('#content .the-reasons-wrapper .section-reasons').hide();
		jQuery('#content .the-reasons-wrapper .section-reasons:eq('+el.index()+')').fadeIn(800);
		jQuery('#content .the-reasons-wrapper .reasons-title').prepend('<span class="tmpTitle">'+jQuery('#content .the-reasons-wrapper .reasons-section-title:eq('+el.index()+')').text()+'</span>');
		jQuery('#content .the-reasons-wrapper .select-wrapper input.pselect').width(jQuery('#content .the-reasons-wrapper .reasons-title .tmpTitle').width()+24);
		jQuery('#content .the-reasons-wrapper .reasons-title .select-wrapper').width(jQuery('#content .the-reasons-wrapper .reasons-title .tmpTitle').width()+24);
		var contWidth = jQuery('#content .the-reasons-wrapper .reasons-title .tmpTitle').width()+24;
		jQuery('#content .the-reasons-wrapper .reasons-title .select-wrapper ul').show();
		var ulWidth = jQuery('#content .the-reasons-wrapper .reasons-title .select-wrapper ul').width();
		jQuery('#content .the-reasons-wrapper .reasons-title .select-wrapper ul').hide();
		jQuery('#content .the-reasons-wrapper .reasons-title .select-wrapper ul').css({'left':-((ulWidth-contWidth)/2)});
		jQuery('#content .the-reasons-wrapper .pselect').val(jQuery('.tmpTitle').text());
		var fixTag = 'reason-'+jQuery('#content .the-reasons-wrapper .section-reasons:eq('+el.index()+')').attr('par-type');
		doFixHeight(fixTag);
		jQuery('#content .the-reasons-wrapper .reasons-title .tmpTitle').remove();
	}
	
	
	//About - Pics Element
	jQuery('#content .about-pics-wrapper .about-box.textual').click(function(){
		jQuery(this).toggleClass('open');
		jQuery(this).parent().next().toggle();
		doFixHeight();
		jQuery(this).parent().next().toggle();
		jQuery(this).parent().next().slideToggle();
		jQuery('html, body').animate({scrollTop: jQuery(this).find('.scroll-anchor').offset().top - (jQuery('#header').height())});
		jQuery(this).parent().next().toggleClass('open');
		if(jQuery(this).hasClass('hi')) {
			jQuery(this).parent().next().find('.icon img + img').each(function(){
				jQuery(this).attr('src', jQuery(this).attr('src')+'?v='+Math.random()); 
			});
			
		}
		if(jQuery(this).parent().next().hasClass('fast')) {
			var vid = document.getElementById("about-vid");
			if(jQuery(this).hasClass('open')) {
				vid.play();
			} else {
				vid.pause();
				vid.currentTime = 0;
			}
		}
	});
	
	jQuery('#content .about-pics-wrapper .about-row.expendable .wrapper.fast .pumi-video > a').click(function(){
		var vid = document.getElementById("about-vid");
		vid.pause(); 
		vid.currentTime = 0;
		jQuery('#vid-popup iframe').attr('src', jQuery('#vid-popup').attr('vimeo-link'));
		jQuery('#vid-popup').delay(500).fadeIn();
		return false;
	});
	jQuery('#vid-popup .darker, #vid-popup .x-btn').click(function(){
		jQuery(this).parents('#vid-popup').fadeOut();
		jQuery('#vid-popup iframe').attr('src', '#');
		return false;
	});
	
	
	
	jQuery('#content .about-pics-wrapper .about-row.expendable .x-btn').click(function(){
		jQuery(this).parent().slideUp();
		jQuery(this).parent().prev().find('.about-box.open').removeClass('open');
		var vid = document.getElementById("about-vid");
		vid.pause(); 
		vid.currentTime = 0;
	});
	
	jQuery('#content .newsletter .subscription-button').click(function(){
		jQuery('#content .pumipopup-form').fadeIn();
	});
	
	jQuery('#content .pumipopup-form .close-btn, #content .pumipopup-form .darker').click(function(){
		jQuery(this).parents('.pumipopup-form').fadeOut();
	});
	
	if( jQuery('#content .leadership .pics .quote-wrapper.active-quote').length < 1 )
		jQuery('#content .leadership .pics .member:first-child + .quote-wrapper').addClass('active-quote');
	jQuery('#content .leadership .pics .member').mouseenter(function(){
		if(jQuery(this).next().hasClass('active-quote'))
			return false
		jQuery(this).parent().find('.quote-wrapper').removeClass('active-quote');
		jQuery(this).next().addClass('active-quote');
	});
	
	
	//Digital Strategy - Mobile Popup
	jQuery('#content .items.strategy .item .header').click(function(){
	jQuery('#strategy-mobile-popup .content-wrapper .item-title').html(jQuery(this).text());
	jQuery('#strategy-mobile-popup .content-wrapper .item-content').html(jQuery(this).siblings('.item-content').html());
		jQuery('#strategy-mobile-popup').fadeIn();
	});
	jQuery('#strategy-mobile-popup .draker, #strategy-mobile-popup .x-btn').click(function(){
		jQuery(this).parents('#strategy-mobile-popup').fadeOut();
		return false;
	});
	
	
	//Sketch Slider (small)
	sketchSlider = jQuery('#content .sketch-slider'); 
	if ( sketchSlider.length ) {
		jslides = sketchSlider.find('.slide');
		if ( jslides.length > 1 ) {
			jcontrols = jQuery('<div class="controls clearfix">');
			jcontrols.append('<a href="javascript:void(0)" class="arrow prev">');
			jcontrols.append('<a href="javascript:void(0)" class="arrow next">');
			jdots = jQuery('<div class="dots">');
			jslides.each(function() {
				jdots.append('<div class="dot">');
			});
			jdots.children().first().addClass('active');
			jslides.first().addClass('active');
			sketchSlider.find('.slide-images').first().addClass('active');

			jcontrols.append(jdots);
			sketchSlider.prepend(jcontrols);

			sketchSlider.find('.slide-images').show().addClass('hide').find('.item').each(function() {
				jitem = jQuery(this);
				var delayClass = ( ( jitem.index() == 0 ) ? '' : ( ( jitem.index() == 1 ) ? 'delay-03s' : 'delay-06s' ) );
				jitem.
					prepend(jQuery('<div>').addClass('flip-container horizontal').addClass(delayClass).
						append(jQuery('<div>').addClass('flipper').
							append(jQuery('<div>').addClass('front').
								append(jitem.children('img').clone())).
							append(jQuery('<div>').addClass('back').
								append(jitem.children('img').clone())
							)
						)
					);
			});

			sketchSlider.find('.arrow.prev').click(function() {
				var slideIndex = sketchSlider.find('.slide.active').index()-1;
				showSlide( sketchSlider, slideIndex );
				flipSlideImages( sketchSlider, slideIndex );
			});
			sketchSlider.find('.arrow.next').click(function() {
				var slideIndex = sketchSlider.find('.slide.active').index()+1;
				showSlide( sketchSlider, slideIndex );
				flipSlideImages( sketchSlider, slideIndex );
			});
			sketchSlider.find('.dot').click(function() {
				var slideIndex = jQuery(this).index();
				if(jQuery(window).width() <= 599){
					//jQuery(this).siblings('.dot').removeClass('active');
					//jQuery(this).addClass('active');
					showSlide( sketchSlider, slideIndex );
					sketchSlider.find('.mobile-slider').css({ 'left' : -(304*(slideIndex)) });
				} else {
					showSlide( sketchSlider, slideIndex );
					flipSlideImages( sketchSlider, slideIndex );
				}
			});
		}
	}
	
	
	//Testimonials Slider (small)
	quoteSlider = jQuery('#content .quotes-slider'); 
	if ( quoteSlider.length ) {
		quoteSlider.each(function(){
			var jslider = jQuery(this);
			jslides = jQuery(this).find('.slide');
			if ( jslides.length > 1 ) {
				jcontrols = jQuery('<div class="controls clearfix">');
				jdots = jQuery('<div class="dots">');
				jslides.each(function() {
					jdots.append('<div class="dot">');
				});
				jdots.children().first().addClass('active');
				jslides.first().addClass('active');

				jcontrols.append(jdots);
				jQuery(this).append(jcontrols);

				quoteSlider.find('.dot').click(function() {
					showSlide( jQuery('#content .quotes-slider'), jQuery(this).index() );
				});
				
				function quoteSlide() {
					showSlide( jslider, (quoteSlider.find('.slide.active').index() + 1) );
				}
				quoteInter = setInterval(function() {quoteSlide()}, 7000 );
				quoteSlider.find('.dot').mouseenter(function(){
					clearInterval(quoteInter);
				});
				quoteSlider.find('.dot').mouseout(function(){
					quoteInter = setInterval(function() {quoteSlide()}, 7000 );
				});
			}
			jslider.click(function(e) {
				if ( !jQuery(e.target).hasClass('dot') ) {
					var jnext = jslider.find('.dot.active').next('.dot');
					if ( !jnext.length )
						jnext = jslider.find('.dot:first-child');
					showSlide( jslider, jnext.index() );
				}
			});
		});
	}

	//Template Page Slider
	jslider = jQuery('.template-slider');
	if ( jslider.length ) {
		jslides = jslider.find('.slide');
		if ( jslides.length > 1 ) {
			jcontrols = jQuery('<div class="controls">');
			jcontrols.append('<a href="javascript:void(0)" class="arrow prev"><span></span></a>');
			jcontrols.append('<a href="javascript:void(0)" class="arrow next"><span></span></a>');
			jdots = jQuery('<div class="dots">');
			jslides.each(function() {
				jdots.append('<div class="dot">');
			});
			jdots.children().first().addClass('active');
			jslides.first().addClass('active');

			jcontrols.append(jdots);
			if(jslider.find('.site-width').length)
				jslider.find('.site-width').prepend(jcontrols);
			else
				jslider.prepend(jcontrols);

			jslider.find('.arrow.prev').click(function() {
				showSlide( jQuery('.template-slider'), jslider.find('.slide.active').index()-1 );
			});
			jslider.find('.arrow.next').click(function() {
				showSlide( jQuery('.template-slider'), jslider.find('.slide.active').index()+1 );
			});
			jslider.find('.dot').click(function() {
				showSlide( jQuery('.template-slider'), jQuery(this).index() );
			});
		}
	}
	
	jQuery('#content .del-button').click(function(){
		if( jQuery(window).width() < 600 ) {
			
			jQuery('.mobile-icons-popup .popup-content').html( jQuery(this).parent().siblings('.del-icons').html() ).find('.x-btn').click(function(){
				jQuery(this).parents('.mobile-icons-popup').fadeOut();
				return false;
			});
			jQuery('.mobile-icons-popup').css({'top': jQuery(window).scrollTop()+jQuery('#header').height()+10}).fadeIn();
		} else {
			if( jQuery(this).parents('.process-hl-wrapper').length > 0 )
				jQuery(this).parent().parent().parent().parent().parent().addClass('open');
			else
				jQuery(this).parent().parent().parent().parent().addClass('open');
		}
		return false;
	});
	jQuery('.mobile-icons-popup .darker').click(function() {
		if( jQuery(window).width() < 600 ) {
			jQuery(this).parents('.mobile-icons-popup').fadeOut();
			return false;
		}
	});
	jQuery('#content .del-icons .x-btn').click(function(){
		if( jQuery(this).parents('.process-hl-wrapper').length > 0 )
			jQuery(this).parent().parent().parent().parent().parent().removeClass('open');
		else
			jQuery(this).parent().parent().parent().parent().removeClass('open');
		return false;
	});
	jQuery('#content.process .del-icons a.item').click(function(){
		var jdelpop = jQuery(this).parents('#content').find('.del-popup');
		jdelpop.fadeIn();
		jdelpop.find('.popup-title').html( jQuery(this).next().find('.popup-title').html());
		jdelpop.find('.popup-text').html( jQuery(this).next().find('.popup-text').html());
		return false;
	});
	jQuery('#content.process .del-popup .x-btn, #content.process .del-popup .darker').click(function(){
		jQuery(this).parents('.del-popup').fadeOut();
		return false;
	});

	var pageUrl = window.location.href;
	if( (pageUrl.indexOf('#') > 0) && jQuery('#project-process').length ) {
		var hash1Index = pageUrl.indexOf('#');
		var hash2Index = pageUrl.indexOf('#', hash1Index+1);
		if(hash2Index > 0)
			var hash1String = pageUrl.substr(hash1Index, hash2Index-hash1Index);
		else
			var hash1String = pageUrl.substr(hash1Index, pageUrl.length=hash1Index);
		jQuery(hash1String).parent().addClass('active');
		jQuery('#content .project-process .info-block:eq('+jQuery('#content .project-process .process-tabs li.active').index()+')').addClass('active');
		
		if(hash2Index > 0) {
			
			var hash2String = pageUrl.substr(hash2Index, pageUrl.length-hash2Index);
			jQuery('#content .fullp-element'+hash2String).addClass('active').siblings('.active').removeClass('active');
			jQuery('#content .project-process .info-block.active .slide:eq('+jQuery('#content .project-process .info-block.active .sec-tabs-block .fullp-element.active').index()+')').addClass('active').siblings('.slide').removeClass('active');
			jQuery('#content.process .info-block.active .process-bullets:eq('+jQuery('#content .project-process .info-block.active .sec-tabs-block .fullp-element.active').index()+')').addClass('active').siblings('.process-bullets').removeClass('active');
		}
	}
	if(jQuery('#content .project-process .process-tabs li.active').length == 0 )
		jQuery('#content .project-process .process-tabs li:first-child').addClass('active');
	if(jQuery('#content .project-process .info-block.active').length == 0 )
		jQuery('#content .project-process .info-block:first-child').addClass('active');
	
	jQuery('#content .project-process .infographics-wrapper .info-block > .slides').each(function(){
		if(jQuery(this).find('.slide.active').length == 0) {
			console.log('no active');
			jQuery(this).find('.slide').first().addClass('active');
		}
	});
	if(jQuery('#content .project-process .infographics-wrapper .info-block > .slides .slide.active').length == 0 ) {
		jQuery('#content .project-process .infographics-wrapper .info-block > .slides .slide:first-child').addClass('active');
	}
	if(jQuery('#content.process .process-bullets.active').length == 0 )
		jQuery('#content.process .process-bullets:first-child').addClass('active');
	
	jQuery('#content .project-process .process-tabs li a').click(function(){
		var itemIndex = jQuery(this).parent().index();
		jQuery(this).parent().siblings('li').removeClass('active');
		jQuery(this).parent().addClass('active');
		jQuery(this).parents('.process-tabs').siblings('.process-tabs').find('li:eq('+itemIndex+')').addClass('active').siblings('li').removeClass('active');
		jQuery(this).parents('.process-tabs').siblings('.infographics-wrapper').find('.info-block').removeClass('active');
		jQuery(this).parents('.process-tabs').siblings('.infographics-wrapper').find('.info-block:eq('+itemIndex+')').addClass('active');
		jQuery('#content.process .bullets-block:eq('+itemIndex+')').addClass('active').siblings('.bullets-block').removeClass('active');
		//
		fullProcessLayout();
		return false;
	});
	
	fullProcessLayout();
	
	jQuery('#content .project-process .fullp-top .fullp-btn.leaner').click(function(){
		if( jQuery(this).parent().parent().hasClass( 'mobile-secondary-menu') ) {
			var currentSlide = jQuery(this).closest('.infographics-wrapper').find('.info-block.active .slide.active');
			var currentSlideIndex = currentSlide.index();
			if( currentSlideIndex > 0 ) {
				currentSlide.removeClass('active').prev().addClass('active');
				jQuery('#content.process .process-bullets.active').prev().addClass('active').siblings('.process-bullets').removeClass('active');
			}
		} else {
			var currentSlide = jQuery(this).closest('.infographics-wrapper').find('.info-block.active .slide.active');
			var currentSlideIndex = currentSlide.index();
			if( currentSlideIndex > 0 ) {
				jQuery(this).siblings('.main-area').find('.fullp-element.active').prev().addClass('active').css({'width': 'auto'}).siblings('.active').removeClass('active');

				currentSlide.removeClass('active').prev().addClass('active');
				jQuery('#content.process .process-bullets.active').prev().addClass('active').siblings('.process-bullets').removeClass('active');

				fullProcessLayout();
			}
		}
		return false;
	});
	jQuery('#content .project-process .fullp-top .fullp-btn.wider').click(function(){
		if( jQuery(this).parent().parent().hasClass( 'mobile-secondary-menu') ) {
			var currentSlide = jQuery(this).parents('.infographics-wrapper').find('.info-block.active .slide.active');
			var currentSlideIndex = currentSlide.index();
			var totalSlides = jQuery(this).siblings('.main-area').find('.fullp-element').length;
			if( currentSlideIndex < jQuery(this).parents('.infographics-wrapper').find('.info-block.active .slide').length - 1 && (currentSlideIndex+1) < totalSlides ) {
				currentSlide.removeClass('active').next().addClass('active');
				jQuery('#content.process .process-bullets.active').next().addClass('active').siblings('.process-bullets').removeClass('active');
			}
		} else {
			var currentSlide = jQuery(this).closest('.infographics-wrapper').find('.info-block.active .slide.active');
			var currentSlideIndex = currentSlide.index();
			var totalSlides = jQuery(this).siblings('.main-area').find('.fullp-element').length;
			if( currentSlideIndex < jQuery(this).closest('.infographics-wrapper').find('.info-block.active .slide').length - 1 && (currentSlideIndex+1) < totalSlides ) {
				jQuery(this).siblings('.main-area').find('.fullp-element.active').next().addClass('active').css({'width': 'auto'}).siblings('.active').removeClass('active');

				currentSlide.removeClass('active').next().addClass('active');
				jQuery('#content.process .process-bullets.active').next().addClass('active').siblings('.process-bullets').removeClass('active');

				fullProcessLayout();
			}
		}
		return false;
	});
	
	//
	jQuery('#content .project-process .fullp-top .fullp-element').each(function(){
		var currentSlide = jQuery(this).parents('.info-block');
		var currentSlideIndex = currentSlide.index();
		var fullpID = jQuery('#content .project-process .process-tabs li:eq('+currentSlideIndex+') a').attr('id');
		jQuery(this).attr('href', "#"+fullpID+"#"+jQuery(this).attr('id'));
	});
	var fullpID = jQuery('#content .project-process .process-tabs li a').each(function(){
		jQuery(this).attr('href', "#"+jQuery(this).attr('id'));
	});
	//
	
	jQuery('#content .project-process .fullp-top').each(function(){
		if(jQuery(this).find('.fullp-element.active').length == 0) {
			jQuery(this).find('.fullp-element').first().addClass('active');
		}
	});
	jQuery('#content .project-process .fullp-top .fullp-element').click(function(){
		var fpElIndex = jQuery(this).index();
		jQuery(this).parent().find('.fullp-element:eq('+fpElIndex+')').addClass('active').css({'width': 'auto'}).siblings('.active').removeClass('active');

		var slidesWrapper = jQuery(this).closest('.infographics-wrapper');
		slidesWrapper.find('.info-block.active .slide.active').removeClass('active');
		slidesWrapper.find('.info-block.active .slide:eq('+fpElIndex+')').addClass('active');
		slidesWrapper.find('.info-block.active .process-bullets:eq('+fpElIndex+')').addClass('active').siblings('.process-bullets').removeClass('active');

		fullProcessLayout();
		return false;
	});
	
	jQuery('#content .project-process .infographics-wrapper .info-item.with-popup').click(function(){
		jQuery('#content .project-process .info-popup .popup-content > div').html( jQuery(this).next().find('.popup-content > div').html() );
		jQuery('#content .project-process .info-popup').fadeIn();
		return false;
	});
	jQuery('#content .project-process .info-popup .x-btn, #content .project-process .info-popup .darker').click(function(){
		jQuery(this).parents('.info-popup').fadeOut();
		return false;
	});
	
	
	// Process Mobile
	jQuery('#content .project-process .mobile-chosen').val( jQuery('#content .project-process .process-tabs li').first().text() ).click(function(){
		jQuery(this).siblings('ul').slideToggle();
	});
	
	jQuery('#content .project-process .process-tabs li a').click(function(){
		jQuery(this).parent().parent().siblings('.mobile-chosen').val(jQuery(this).text()).change();
		if ( winWidth <= 600 )
			jQuery(this).parents('ul').slideToggle();
		else
			jQuery(this).parents('ul').toggle();
	});
	
	
	//Expert's Page Popup
	jQuery('#content .our-expertese .items .item').click(function(){
		expertPopup(jQuery(this));
		return false;
	});
	
	function expertPopup( el ) {
		var jpop = jQuery('#experts-popup');
		jpop.find('.item-img').html(el.find('.item-img').html());
		jpop.find('.item-title').html(el.find('.item-text').html());
		jpop.find('.item-content').html(el.find('.item-content').html());
		jpop.find('.line').css({'background-color': el.find('.line').css('background-color')});
		jpop.fadeIn();
	}
	
	jQuery('#experts-popup .x-btn, #experts-popup .darker').click(function(){
		jQuery(this).parents('#experts-popup').fadeOut();
		return false;
	});
	
	//Service Full Page Popup
	jQuery('#content .services-list.full li a, #content .deliverables-wrapper .del-items li a').click(function(){
		servicePopup(jQuery(this).parents('li'), '3');
		return false;
	});
	
	function servicePopup( el, iid ) { //'1' for icon item. '2' for textual item.
		var jpop = jQuery('#service-icon-popup');
		if(iid=='1') {
			jpop.find('.item-img').html(el.find('.img-wrapper').html());
			jpop.find('.item-title').html(el.find('.item-text').html());
		} else if(iid=='2') {
			jpop.find('.item-img').html('<img src="'+ el.attr('pop-icon') + '">');
			jpop.find('.item-title').html(el.find('.service-type-title').text());
		} else {
			jpop.addClass('list');
			jpop.find('.item-img').html(el.find('.img-wrapper').html());
			jpop.find('.item-title').html(el.find('.item-text').html());
			//jpop.find('.footer-text').html('');
		}
		jpop.find('.item-content').html(el.find('.pop-text').html());
		jpop.fadeIn();
	}
	jQuery('#service-icon-popup .x-btn, #service-icon-popup .darker').click(function(){
		jQuery(this).parents('#service-icon-popup').fadeOut();
		return false;
	});
	
	jQuery('#content .textual-wrapper li a').click(function(){
		servicePopup(jQuery(this).parents('li'), '2');
		return false;
	});
	
	jQuery('#content .pumi-gen-popup .list-popup-btn').click(function(){
		jQuery(this).parents('#service-icon-popup').fadeOut(600, function(){
			jtarget = jQuery('#content .contact');
			var jtargetTop = jtarget.offset().top;
			if ( jtarget ) {
				var scrollTarget = (jtargetTop)-(jQuery('#header').outerHeight(true))-40;
				jQuery('html, body').animate({scrollTop: scrollTarget},500, function(){
					jQuery('#content .contact .contact-button').click();
				});
			}
		});
		return false;
	});
	
	
	jQuery('#content .leadership .pics .member').click(function(){
		if( jQuery(window).width() > 375 ) {
			if(jQuery(this).hasClass('aviran')){
				jQuery(this).parent().parent().parent().siblings('.leadership-popup.aviran').fadeIn();
			} else {
				jQuery(this).parent().parent().parent().siblings('.leadership-popup.shahaf').fadeIn();
			}
			return false;
		}
	});
	jQuery('#content .leadership-popup .x-btn, #content .leadership-popup .darker').click(function(){
		jQuery(this).parents('.leadership-popup').fadeOut();
		return false;
	});
	
	
	var jobsSlider = jQuery('#image-header.jobs .main .jobs-slider');
	jobsSlideChanged(jobsSlider.find('.slide').first().addClass('active'));
	jobsSlider.find('.slide .next').click(function() {
		var jnewSlide = moveSlide( (jobsSlider.find('.slide.active').index()+1), 'full', jobsSlider );
		jobsSlideChanged( jnewSlide );
		return false;
	});
	
	if(jQuery('#content .websites-tabs-wrapper .websites-tabs .tab-content.active').length < 1) {
		jQuery('#content .websites-tabs-wrapper .websites-tabs .tab-content').first().addClass('active');
	}
	jQuery('#content .websites-tabs-wrapper .websites-tabs .chosen-tab').text(jQuery('#content .websites-tabs-wrapper .websites-tabs .tabs-list li a').first().text());
	jQuery('#content .websites-tabs-wrapper .websites-tabs .chosen-tab').click(function(){
		jQuery(this).siblings('.tabs-list').slideToggle();
	});
	jQuery('#content .websites-tabs-wrapper .websites-tabs .tabs-list li a').click(function(){
		if(jQuery(window).width() < 600 ) {
			jQuery(this).parents('.tabs-list').slideToggle();
			jQuery(this).parents('.tabs-list').siblings('.chosen-tab').text(jQuery(this).text());
		}
		jQuery(this).parent().addClass('active').siblings('li').removeClass('active');
		var tTrigger = jQuery(this).parent().index();
		jQuery(this).parent().parent().siblings('.tabs-content').find('.tab-content.active').removeClass('active').fadeOut(function(){
			jQuery(this).parent().find('.tab-content:eq('+tTrigger+')').fadeIn().addClass('active');
		});
		return false;
	});
	

	var joneOfUsItems = jQuery('#content .one-of-us .items');
	if ( joneOfUsItems.length ) {
		joneOfUsItems.children('.item').addClass('hide');
		for ( var i = 0; i < 4; i++ ) {
			joneOfUsItems.
				append(jQuery('<div>').addClass('item flip-container vertical').
					append(jQuery('<div>').addClass('flipper').
						append(jQuery('<div>').addClass('front').
							append(joneOfUsItems.children('.item.hide').random().removeClass('hide'))).
						append(jQuery('<div>').addClass('back'))
					));
		}
		var isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
		if( !isTouch ) {
			joneOfUsItems.find('.item.flip-container').mouseenter(function() {
				var jflipContainer = jQuery(this);
				var jrandomItem = joneOfUsItems.children('.item.hide').random().removeClass('hide');
				if ( !jrandomItem.length ) {
					joneOfUsItems.find('.flip-container:not(.show-back) .back .item, .flip-container.show-back .front .item').addClass('hide').appendTo(joneOfUsItems);
					jrandomItem = joneOfUsItems.children('.item.hide').random().removeClass('hide');
				}
				if ( jrandomItem.length ) {
					if ( jflipContainer.hasClass('show-back') ) {
						jflipContainer.find('.front .item').addClass('hide').appendTo(joneOfUsItems);
						jflipContainer.find('.front').append(jrandomItem);
						jflipContainer.removeClass('show-back');
					} else {
						jflipContainer.find('.back .item').addClass('hide').appendTo(joneOfUsItems);
						jflipContainer.find('.back').append(jrandomItem);
						jflipContainer.addClass('show-back');
					}
				}
			})
		} else {
			joneOfUsItems.find('.item.flip-container').click(function(){
				var jflipContainer = jQuery(this);
				var jrandomItem = joneOfUsItems.children('.item.hide').random().removeClass('hide');
				if ( !jrandomItem.length ) {
					joneOfUsItems.find('.flip-container:not(.show-back) .back .item, .flip-container.show-back .front .item').addClass('hide').appendTo(joneOfUsItems);
					jrandomItem = joneOfUsItems.children('.item.hide').random().removeClass('hide');
				}
				if ( jrandomItem.length ) {
					if ( jflipContainer.hasClass('show-back') ) {
						jflipContainer.find('.front .item').addClass('hide').appendTo(joneOfUsItems);
						jflipContainer.find('.front').append(jrandomItem);
						jflipContainer.removeClass('show-back');
					} else {
						jflipContainer.find('.back .item').addClass('hide').appendTo(joneOfUsItems);
						jflipContainer.find('.back').append(jrandomItem);
						jflipContainer.addClass('show-back');
					}
				}
			})
		}
				
		
	}

	// Initialize produce animation
	var jproduceAnimation = jQuery('#content.process .produce');
	if ( jproduceAnimation.length ) {
		produceAnimation.exists = true;
		produceAnimation.jel = jproduceAnimation;
		produceAnimation.jimageWrapper = produceAnimation.jel.find('.image-wrapper');
		produceAnimation.jdivs = produceAnimation.jimageWrapper.find('div');
	}
	
	//Page: What Is Pumika
	jQuery('#content.what-is .mainp.sound .sound-icon-wrapper').click(function(){
		var audio = jQuery('#content.what-is .mainp.sound #whatis-sound').get(0);
		audio.play();
		return false;
	});

	setInterval( function() {
		var jofficeImages = jQuery('#content .office-images-wrapper .office-image');
		var jvisibleImages = jofficeImages.not('.hidden');
		var jimageToHide = jQuery(jvisibleImages.get(Math.round(Math.random()*(jvisibleImages.length-1))));
		var jhiddenImages = jofficeImages.filter('.hidden');
		var jimageToShow = jQuery(jhiddenImages.get(Math.round(Math.random()*(jhiddenImages.length-1))));
		jimageToHide.before(jimageToShow);
		jimageToHide.addClass('hidden');
		jimageToShow.removeClass('hidden');
	}, 3000 );

	// contact form
	var hashString = window.location.hash.substr(1);
	var activeTabIndex = -1;
	switch (hashString) {
		case 'newbiz':
			activeTabIndex = 0;
			break;
		case 'partner':
			activeTabIndex = 1;
			break;
		case 'media':
			activeTabIndex = 2;
			break;
		case 'job':
			activeTabIndex = 3;
			break;
		case 'hello':
			activeTabIndex = 4;
			break;
	}
	if (activeTabIndex >= 0 && activeTabIndex <= 4) {
		jQuery('#content .contact .form-wrapper .interested-in .sec-title').click();
		jQuery( '#content .contact .pumika-contact-form .interested-in .tabs-menu li:eq(' + activeTabIndex + ') a').click();
	}

	// full services list
	if (hashString.substr(0, 8) == 'service-') {
		jQuery('.services-filter .filter-select .select-wrapper ul li').filter('[datavalue="' + hashString + '"]').click();
	}

	jQuery ('#content .services-filter').css("visibility", "visible");



		// file upload support

	jQuery( '.message-wrapper .attach-button' ).click(function(){
		jQuery( '.message-wrapper .attach-button .console' ).hide();
	});

	jQuery( '.message-wrapper .attach-button .remove' ).click(function(){
		jQuery( '.message-wrapper .attach-button .filename-status' ).empty();
		jQuery( '.message-wrapper .attach-button' ).removeClass('show');
		jQuery( '.message-wrapper .attach-button .pfile').show();
		jQuery( '.message-wrapper .attach-button .remove' ).hide();
		uploadStarted = false;
		uploadedFileUrl = '';
		triedToSubmit = false;
	});

	// newbizUploader

	var newbizUploader = new plupload.Uploader({
		browse_button : 'newbiz-browse', // this can be an id of a DOM element or the DOM element itself
		url : pumika.themeurl + '/upload.php',
		max_file_size : '1mb',
		multi_selection : false,

		// Flash settings
		flash_swf_url : 'plupload/Moxie.swf',

		// Silverlight settings
		silverlight_xap_url : 'plupload/Moxie.xap'
	});

	newbizUploader.init();

	newbizUploader.bind('FilesAdded', function(up, files) {
		var file = files[0];
		var htmlString = '<span id="' + file.id + '">' + file.name + ' (' + plupload.formatSize(file.size) + ') <b></b></span>';
		var jFilename = jQuery( '#content .contact .form-wrapper .message-wrapper .filename-status' );

		jQuery( '.attach-button .console' ).hide();
		jFilename.html(htmlString);
		jFilename.parent().addClass('show');
		jFilename.siblings('.pfile').hide();
		jFilename.siblings('.remove').show();
		uploadStarted = true;
		newbizUploader.start();
	});

	newbizUploader.bind('UploadProgress', function(up, file) {
		document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = '<span>' + file.percent + "%</span>";
	});

	newbizUploader.bind('Error', function(up, err) {
		jQuery( '.attach-button .console' ).show().html("\nError #" + err.code + ": " + err.message);
		if (err.code == '-600') {
			jQuery( '.attach-button .console' ).append( "\nMax file size: 1MB" );
		}
		uploadStarted = false;
	});

	newbizUploader.bind('FileUploaded', function(up, file, res) {
		var resultData = JSON.parse(res.response);
		if ( resultData.success ) {
			uploadedFileUrl = resultData.fileurl;
			jQuery( '.attach-button .console' ).hide();
		} else {
			alert( resultData.errormsg );
		}

		uploadStarted = false;

		if (triedToSubmit) {
			jQuery('#content .form-wrapper .pumika-contact-form').submit();
			triedToSubmit = false;
		}
	});

	// partnerUploader

	var partnerUploader = new plupload.Uploader({
		browse_button : 'partner-browse', // this can be an id of a DOM element or the DOM element itself
		url : pumika.themeurl + '/upload.php',
		max_file_size : '1mb',
		multi_selection : false,

		// Flash settings
		flash_swf_url : 'plupload/Moxie.swf',

		// Silverlight settings
		silverlight_xap_url : 'plupload/Moxie.xap'
	});

	partnerUploader.init();

	partnerUploader.bind('FilesAdded', function(up, files) {
		var file = files[0];
		var htmlString = '<span id="' + file.id + '">' + file.name + ' (' + plupload.formatSize(file.size) + ') <b></b></span>';
		var jFilename = jQuery( '#content .contact .form-wrapper .message-wrapper .filename-status' );

		jQuery( '.attach-button .console' ).hide();
		jFilename.html(htmlString);
		jFilename.parent().addClass('show');
		jFilename.siblings('.pfile').hide();
		jFilename.siblings('.remove').show();
		uploadStarted = true;
		partnerUploader.start();
	});

	partnerUploader.bind('UploadProgress', function(up, file) {
		document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = '<span>' + file.percent + "%</span>";
	});

	partnerUploader.bind('Error', function(up, err) {
		jQuery( '.attach-button .console' ).show().html("\nError #" + err.code + ": " + err.message);
		if (err.code == '-600') {
			jQuery( '.attach-button .console' ).append( "\nMax file size: 1MB" );
		}
		uploadStarted = false;
	});

	partnerUploader.bind('FileUploaded', function(up, file, res) {
		var resultData = JSON.parse(res.response);
		if ( resultData.success ) {
			uploadedFileUrl = resultData.fileurl;
			jQuery( '.attach-button .console' ).hide();
		} else {
			alert( resultData.errormsg );
		}

		uploadStarted = false;

		if (triedToSubmit) {
			jQuery('#content .form-wrapper .pumika-contact-form').submit();
			triedToSubmit = false;
		}
	});

	// jobUploader

	var jobUploader = new plupload.Uploader({
		browse_button : 'job-browse', // this can be an id of a DOM element or the DOM element itself
		url : pumika.themeurl + '/upload.php',
		max_file_size : '1mb',
		multi_selection : false,
		headers: { Authorization: '' },		

		// Flash settings
		flash_swf_url : 'plupload/Moxie.swf',

		// Silverlight settings
		silverlight_xap_url : 'plupload/Moxie.xap'
	});

	jobUploader.init();

	jobUploader.bind('FilesAdded', function(up, files) {
		var file = files[0];
		var htmlString = '<span id="' + file.id + '">' + file.name + ' (' + plupload.formatSize(file.size) + ') <b></b></span>';
		var jFilename = jQuery( '#content .contact .form-wrapper .message-wrapper .filename-status' );

		jQuery( '.attach-button .console' ).hide();
		jFilename.html(htmlString);
		jFilename.parent().addClass('show');
		jFilename.siblings('.pfile').hide();
		jFilename.siblings('.remove').show();
		uploadStarted = true;
		jobUploader.start();
	});

	jobUploader.bind('UploadProgress', function(up, file) {
		document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = '<span>' + file.percent + "%</span>";
	});

	jobUploader.bind('Error', function(up, err) {
		jQuery( '.attach-button .console' ).show().html("\nError #" + err.code + ": " + err.message);
		if (err.code == '-600') {
			jQuery( '.attach-button .console' ).append( "\nMax file size: 1MB" );
		}
		uploadStarted = false;
	});

	jobUploader.bind('FileUploaded', function(up, file, res) {
		if (res && res.response) {
			var resultData = JSON.parse(res.response);
			if ( resultData.success ) {
				uploadedFileUrl = resultData.fileurl;
				jQuery( '.attach-button .console' ).hide();
			} else {
				alert( resultData.errormsg );
			}
			uploadStarted = false;
			
			if (triedToSubmit) {
				jQuery('#content .form-wrapper .pumika-contact-form').submit();
				triedToSubmit = false;
			}
		}
		uploadStarted = false;
	});

	jQuery('.services-filter .filter-select .select-wrapper input[name="service-name"]').change(function() {
		var $this = jQuery(this);
		jServicesFilter = jQuery('.services-filter .services-list');

		if ($this.val() == 'all-services') {
			jServicesFilter.find('.single-service-object-filter').hide();
			jServicesFilter.find('.all-services-object-filter').show();
		} else {
			jServicesFilter.find('.all-services-object-filter').hide();
			jServicesFilter.find('.single-service-object-filter').hide().filter('[data-service-object-id="' + $this.val() + '"]').show();
		}

	}).change();
	
	
	// RESPONSIVE
	var isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
	if( isTouch ) {
		jQuery('#header .menu-container ul li a').on({ 'click' : function(e){
			jQuery(this).addClass('hover');
			jQuery('body').toggleClass('mobile-menu-open');
		} });
	}
	jQuery('body').on({'touchmove': function(e){
		if(scrollTop >= jQuery('body').offset().top)
			doWinScroll();
	}});
	
	jQuery('#header .mobile-menu-trigger').click(function(){
		jQuery('body').toggleClass('mobile-menu-open');
		return false;
	});
	jQuery(document).ready(function(){
	jQuery('#nav-icon').click(function(){
		jQuery(this).toggleClass('open');
	});
	
	jQuery('#content .expertise .items .item').on({ 'tap' : function(e){
		if(jQuery(this).hasClass('hover'))
			return true;
		else
			jQuery(this).addClass('hover');
		return false;
	} });
	
	if( jQuery('#content .case-studies .items .mobile-slider .item').length ) {
		jQuery('#content .case-studies .items .mobile-slider .item:first-child').addClass('active');	
	}
	if( jQuery('#content .our-expertese .items .mobile-slider .item').length ) {
		jQuery('#content .our-expertese .items .mobile-slider .item:first-child').addClass('active');
	}
	var touchStartPos;
	var touchEndPos;
	var currentCaseSlide;
	
	jQuery('#content .case-studies .items, #content .our-expertese .items').on({ 'touchstart' : function(e){
		if( jQuery(window).width() <= 412 ) {
			jQuery(this).addClass('moving');
			touchStartPos = e.originalEvent.touches[0].pageX;
		}
	} });
	
	jQuery('#content .case-studies .items, #content .our-expertese .items').on({ 'touchmove' : function(e){
		currentCaseSlide = jQuery(this).find('.active');
		e.preventDefault();
		endCoords = e.originalEvent.targetTouches[0];
		swipeDistance = e.originalEvent.changedTouches[0].pageX - touchStartPos;
		if(jQuery(this).parents('.our-expertese').length)
			jQuery(this).find('.mobile-slider').css({ 'left' : -(240*(currentCaseSlide.index())) + swipeDistance });
		else
			jQuery(this).find('.mobile-slider').css({ 'left' : -(290*(currentCaseSlide.index())) + swipeDistance });
	}});
	
	jQuery('#content .case-studies .items, #content .our-expertese .items').on({ 'touchend' : function(e){
		currentCaseSlide = jQuery(this).find('.active');
		touchEndPos = endCoords.pageX
		swipeDistance = touchEndPos - touchStartPos;
		if( swipeDistance < 0 ) { //swipe left
			if(currentCaseSlide.index()+1 < jQuery(this).find('.item').length ) {
				if(jQuery(this).parents('.our-expertese').length)
					animateDistance = 240;
				else
					animateDistance = 290;
				if( Math.abs(swipeDistance) > (animateDistance/2)) {
					jQuery(this).find('.mobile-slider').css({ 'left' : -(animateDistance*(currentCaseSlide.index()+1)) });
					currentCaseSlide.removeClass('active');
					currentCaseSlide.next().addClass('active');
				} else {
					jQuery(this).find('.mobile-slider').css({ 'left' : -(animateDistance*(currentCaseSlide.index())) });
				}
			} else if( jQuery(this).find('.mobile-slider').position().left < animateDistance*(currentCaseSlide.index()) ) {
				jQuery(this).find('.mobile-slider').css({ 'left' : -(animateDistance*(currentCaseSlide.index())) });
			}
		} else { //swipe right
			if(jQuery(this).parents('.our-expertese').length)
					animateDistance = 240;
				else
					animateDistance = 290;
			if(currentCaseSlide.index() > 0 ) {
				if( Math.abs(swipeDistance) > (animateDistance/2)) {
					jQuery(this).find('.mobile-slider').css({ 'left' : -(animateDistance*(currentCaseSlide.index()-1)) });
					currentCaseSlide.removeClass('active');
					currentCaseSlide.prev().addClass('active');
				} else {
					jQuery(this).find('.mobile-slider').css({ 'left' : -(animateDistance*(currentCaseSlide.index())) });
				}
			} else if( jQuery(this).find('.mobile-slider').position().left > 0 ) {
				jQuery(this).find('.mobile-slider').css({ 'left' : -(animateDistance*(currentCaseSlide.index())) });
			}
		}
		jQuery(this).removeClass('moving');
	}});
	
	// sketch to product mobile slider
	jQuery('#content .sketch-wrapper .sketch-slider .images').on({ 'touchstart' : function(e){
		if( jQuery(window).width() <= 599 ) {
			jQuery(this).addClass('moving');
			touchStartPos = e.originalEvent.touches[0].pageX;
		}
	} });
	
	jQuery('#content .sketch-wrapper .sketch-slider .images').on({ 'touchmove' : function(e){
		currentCaseSlide = jQuery(this).find('.active');
		e.preventDefault();
		endCoords = e.originalEvent.targetTouches[0];
		swipeDistance = e.originalEvent.changedTouches[0].pageX - touchStartPos;
		jQuery(this).find('.mobile-slider').css({ 'left' : -(304*(currentCaseSlide.index())) + swipeDistance });
	}});
	
	jQuery('#content .sketch-wrapper .sketch-slider .images').on({ 'touchend' : function(e){
		currentCaseSlide = jQuery(this).find('.active');
		touchEndPos = endCoords.pageX
		swipeDistance = touchEndPos - touchStartPos;
		
		if( swipeDistance < 0 ) { //swipe left
			animateDistance = 304;
			if(currentCaseSlide.index()+1 < jQuery(this).find('.slide-images').length ) {
				if( Math.abs(swipeDistance) > (animateDistance/2)) {
					jQuery(this).find('.mobile-slider').css({ 'left' : -(animateDistance*(currentCaseSlide.index()+1)) });
					currentCaseSlide.removeClass('active');
					currentCaseSlide.next().addClass('active');
					jQuery('#content .sketch-wrapper .sketch-slider .dot:eq('+(currentCaseSlide.index()+1)+')').click();
				} else {
					jQuery(this).find('.mobile-slider').css({ 'left' : -(animateDistance*(currentCaseSlide.index())) });
				}
			} else if( jQuery(this).find('.mobile-slider').position().left < animateDistance*(currentCaseSlide.index()) ) {
				jQuery(this).find('.mobile-slider').css({ 'left' : -(animateDistance*(currentCaseSlide.index())) });
			}
		} else { //swipe right
			animateDistance = 304;
			if(currentCaseSlide.index() > 0 ) {
				if( Math.abs(swipeDistance) > (animateDistance/2)) {
					jQuery(this).find('.mobile-slider').css({ 'left' : -(animateDistance*(currentCaseSlide.index()-1)) });
					currentCaseSlide.removeClass('active');
					currentCaseSlide.prev().addClass('active');
					jQuery('#content .sketch-wrapper .sketch-slider .dot:eq('+(currentCaseSlide.index()-1)+')').click();
				} else {
					jQuery(this).find('.mobile-slider').css({ 'left' : -(animateDistance*(currentCaseSlide.index())) });
				}
			} else if( jQuery(this).find('.mobile-slider').position().left > 0 ) {
				jQuery(this).find('.mobile-slider').css({ 'left' : -(animateDistance*(currentCaseSlide.index())) });
			} else {
				jQuery(this).find('.mobile-slider').css({ 'left' : '0' });
			}
		}
		jQuery(this).removeClass('moving');
	}});

	jQuery('.other-services .seen').each(function() {
		if ( getCookie(jQuery(this).attr('data-cookie')) == 'true' )
			jQuery(this).parent().addClass('already-seen');
	});
	
	// Jobs Slider

	jQuery('.new-jobs-slider').addClass('animate').find('.slide').addClass('reset');

	jQuery('.new-jobs-slider .slides .slide .next').click(function() {
		var jcurrentSlide = jQuery(this).closest('.slide');
		if ( !jcurrentSlide.hasClass('hide') ) {
			if ( typeof document.body.style.transition != 'undefined' ) {
				jcurrentSlide.addClass('hide').find('.reason').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function( e ) {
					var jslide = jQuery(this).closest('.slide');
					if ( jslide.hasClass('hide') ) {
						jslide.removeClass('show hide').addClass('reset');
						var jnextSlide = jslide.next('.slide');
						if ( !jnextSlide.length )
							jnextSlide = jQuery('.new-jobs-slider .slide:first-child');
						jnextSlide.removeClass('reset').addClass('show');
					}
				});
			} else {
				jcurrentSlide.fadeOut(function() {
					var jnextSlide = jQuery(this).next('.slide');
					if ( !jnextSlide.length )
						jnextSlide = jQuery('.new-jobs-slider .slide:first-child');
					jnextSlide.fadeIn();
				});
			}

			// Change background
			var jnextSlide = jcurrentSlide.next('.slide');
			if ( !jnextSlide.length )
				jnextSlide = jQuery('.new-jobs-slider .slide:first-child');
			jobsSlideChanged( jnextSlide );
		}
		return false;
	});


	var jaboutPicsWrapper = jQuery('#content .about-pics-wrapper');
	if ( jaboutPicsWrapper.length ) {
		var rowIndex = -1;
		var jaboutPicsRows = jaboutPicsWrapper.find( '.about-row.with-galleries' );
		setInterval(function() {
			rowIndex++;
			if ( rowIndex >= jaboutPicsRows.length )
				rowIndex = 0;

			var jrow = jaboutPicsRows.eq(rowIndex);
			var jbox = jrow.find('.about-box.gallery').random();
			var jactiveImage = jbox.find('img.active');
			if ( !jactiveImage.length )
				jactiveImage = jbox.find('img:first-child');
			var jrandomImage = jactiveImage.siblings('img').random();

			if ( jrandomImage.length ) {
				jactiveImage.removeClass('active').fadeOut();
				jrandomImage.addClass('active').fadeIn();
			}
		}, 3000);
	}
});

}
function showSlideMob( jslider, newSlideIndex ) {
	var jslider = jslider;
	var jcurrentSlide = jslider.find('.slide.active');
	if(jcurrentSlide.length == 0){
		jslider.find('.slide').each(function(){
			if(jQuery(this).css('opacity') == '0') {
				jQuery(this).addClass('active');
				jcurrentSlide = jQuery(this);
				return true;
			}
		});
	}
	var jnewSlide = jslider.find('.slide:eq('+newSlideIndex+')');
	jslider.find('.slide').removeClass('active').animate({'opacity': '0'}, 300);
	jnewSlide.addClass('active').animate({'opacity': '100'}, 300);
}
function showSlide( jslider, newSlideIndex ) {
	var jslider = jslider;
	var jcurrentSlide = jslider.find('.slide.active');
	//console.log(jcurrentSlide);
	if ( jcurrentSlide.length && ( ( currentIndex = jcurrentSlide.index() ) != newSlideIndex ) ) {
		//console.log(newSlideIndex);
		if ( newSlideIndex < 0 )
			var jnewSlide = jslider.find('.slide').last();
		else if ( newSlideIndex >= jslider.find('.slide').length )
			var jnewSlide = jslider.find('.slide').first();
		else
			var jnewSlide = jslider.find('.slide:eq('+newSlideIndex+')');

		jslider.find('.dot.active').removeClass('active');
		jslider.find('.dot:eq('+jnewSlide.index()+')').addClass('active');

		jcurrentSlide.removeClass('active').fadeOut(500, function(){
			
		});
		jnewSlide.addClass('active').fadeIn(500);
	}
}

function jobsSlideChanged( jnewSlide ) {
	var jslideBackground = jQuery('#image-header').children('.slide-background[data-slide="'+jnewSlide.index()+'"]');
	if ( jslideBackground.length ) {
		jslideBackground.siblings('.slide-background.active').removeClass('active').fadeOut(800);
		jslideBackground.hide().addClass('show active').fadeIn(800);
	} else {
		jQuery('#image-header > .slide-background.active').removeClass('active').fadeOut(800);
	}
}

function flipSlideImages( jslider, newSlideIndex ) {
	var jslider = jslider;
	var jcurrentSlide = jslider.find('.slide-images.active');
	if ( jcurrentSlide.length && ( ( currentIndex = jcurrentSlide.index() ) != newSlideIndex ) ) {
		if ( newSlideIndex < 0 )
			var jnewSlide = jslider.find('.slide-images').last();
		else if ( newSlideIndex >= jslider.find('.slide-images').length )
			var jnewSlide = jslider.find('.slide-images').first();
		else
			var jnewSlide = jslider.find('.slide-images:eq('+newSlideIndex+')');

		jcurrentSlide.removeClass('active');
		jnewSlide.addClass('active');

		jcurrentSlide.find('.flip-container').removeClass('show-back');
		jnewSlide.find('.item').each(function() {
			var jitem = jQuery(this);
			jitem.find('.front').empty().append(jcurrentSlide.find('.item:eq('+jitem.index()+')').children('img').clone());
		}).find('.flip-container').addClass('show-back');
	}
}

function fixLogoMargins() {
	// Fix Home Logo Margins
	var minMargin = 40;
	jQuery('#content .clients-list li').each(function(){
		if(jQuery(this).parents('#content').hasClass('clients')) {
			if(jQuery(window).width() <= 768 )
				minMargin = 20;
			else
				minMargin = 60;
		}
		if(jQuery(window).width() <= 375 )
			minMargin = 4;
	});
	jQuery('.partners-main ul li').each(function(){
		//if(jQuery(this).parents('#content').hasClass('clients'))
			//minMargin = 60;
		if(jQuery(window).width() <= 375 )
			minMargin = 8;
	});
	var jimages = jQuery('.partners-main ul li, #content .clients-list li').css('marginLeft',minMargin).css('marginRight',minMargin);
	var jrow = jQuery();
	var rowTop = 0;
	// Loop all images
	jimages.each(function() {
		// If not first in row
		
		if ( jrow.length ) {
			// If in same row
			if ( jQuery(this).offset().top == rowTop ) {
				// Add to row
				jrow = jrow.add(this);
			// If in new row
			} else {
				// Calc best margin
				var marginOffset = minMargin;
				if ( jrow.length > 1 ) {
					while ( jrow.first().offset().top == jrow.last().offset().top ) {
						marginOffset++;
						jrow.css('marginLeft',marginOffset+'px').css('marginRight',marginOffset+'px');
						jrow.first().css('marginLeft',minMargin);
						jrow.last().css('marginRight',minMargin);
					}
				}

				// Apply best margin
				jrow.css('marginLeft',(marginOffset-1)+'px').css('marginRight',(marginOffset-1)+'px');
				jrow.first().css('marginLeft',minMargin);
				jrow.last().css('marginRight',minMargin);

				// Reset row data
				jrow = jQuery();
			}
		}

		// If first in row
		if ( !jrow.length ) {
			// Init row data
			jrow = jrow.add(this);
			rowTop = jrow.offset().top;
		}
	});

	// Fix last row
	if ( jrow.length ) {
		// Calc best margin
		var marginOffset = minMargin;
		if ( jrow.length > 1 ) {
			while ( jrow.first().offset().top == jrow.last().offset().top &&  marginOffset <= 121 ) {
				marginOffset++;
				jrow.css('marginLeft',marginOffset+'px').css('marginRight',marginOffset+'px');
				jrow.first().css('marginLeft',minMargin);
				jrow.last().css('marginRight',minMargin);
			}
		}

		// Apply best margin
		jrow.css('marginLeft',(marginOffset-1)+'px').css('marginRight',(marginOffset-1)+'px');
		jrow.first().css('marginLeft',minMargin);
		jrow.last().css('marginRight',minMargin);
	}
}

//move
function moveSlide( newSlideIndex, mode, slider ) {
	mode = typeof mode !== 'undefined' ? mode : 'full';
	var jslider = slider;
	jcurrentSlide = jslider.find('.slide.active');
	if ( jcurrentSlide.length && ( ( currentIndex = jcurrentSlide.index() ) != newSlideIndex ) ) {
		if ( newSlideIndex < 0 )
			jnewSlide = jslider.find('.slide').last();
		else if ( newSlideIndex >= jslider.find('.slide').length )
			jnewSlide = jslider.find('.slide').first();
		else
			jnewSlide = jslider.find('.slide:eq('+newSlideIndex+')');

		if(mode=='full'){
			jcurrentSlide.removeClass('active').animate({left: ((newSlideIndex > currentIndex) ? '-100%' : '100%')},600);
			jnewSlide.addClass('active').show().css('left',(newSlideIndex > currentIndex) ? '100%' : '-100%').animate({left: '0%'},600);
		}else if(mode == 'part'){
			var jslides = jslider.find('.slides');
			var slideWidth = jcurrentSlide.outerWidth(true);
			jslides.animate({left: ((newSlideIndex > currentIndex) ? '-='+slideWidth+'px' : '+='+slideWidth+'px')},600);
			jcurrentSlide.removeClass('active');
			jnewSlide.addClass('active');
			
		}

		return jnewSlide;
	}
	return false;
}

function fullProcessLayout() {
	var jinfoBlock = jQuery('#content .project-process .info-block.active');
	var jfullProcessElements = jinfoBlock.find('.sec-tabs-block .fullp-top .fullp-element');
	var jactiveElement = jfullProcessElements.filter('.active');

	if ( !jinfoBlock.length || !jfullProcessElements.length || !jactiveElement.length )
		return;

	// Fix learner-wider button layout
	var fpMainWidth = jinfoBlock.find('.sec-tabs-block .fullp-top .main-area').width();
	var fpFreeSpace = fpMainWidth - jactiveElement.width();
	var numOfBtns = jfullProcessElements.length;
	var fpElWidth = Math.floor( ( fpFreeSpace / ( numOfBtns - 1 ) ) * 0.8 ) + 1;
	jfullProcessElements.filter(':not(.active)').width(fpElWidth);

	doFixWidth( jactiveElement.attr('data-fix-width') );
}

function getCookie(cname) {
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for(var i = 0; i <ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length,c.length);
		}
	}
	return "";
}

function compareLogosHeight(){
	var jitems = jQuery('#content .brands-logos-wrapper .brands-logos');
	var itemsInitHeight = jitems.css('height','').height();
	jitems.addClass('open');
	var itemsFullHeight = jitems.height();
	jitems.removeClass('open');
	if( itemsInitHeight == itemsFullHeight ) {
		jitems.parent().addClass('no-more');
	} else {
		jitems.parent().removeClass('no-more');
	}
}
