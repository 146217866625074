jQuery(document).ready(function() {
	jQuery('#cf2dbtable td[title="email"] div').each(function() {
		jQuery(this).html('<a href="mailto:' + jQuery(this).html() + '">' + jQuery(this).html() + '</a>');
	});
	jQuery('#cf2dbtable td[title="portfolio-link"] div, #cf2dbtable td[title="website"] div, #cf2dbtable td[title="file"] div').each(function() {
		var url = jQuery(this).html();
		if (!url.match(/^[a-zA-Z]+:\/\//)) {
			url = 'http://' + url;
		}
		jQuery(this).html('<a target="_blank" href="' + url + '">' + jQuery(this).html() + '</a>');
	});
});
